import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/products';
import CreateShoppingListModal from '../components/ShoppingLists/CreateShoppingListModal';
import DeleteShoppingListModal from '../components/ShoppingLists/DeleteShoppingListModal';
import ShoppingListProducts from '../components/ShoppingLists/ShoppingListProducts';
import { updateCart } from '../state/cartSlice';
import { MyAccountTitle } from '../styles';

const ShoppingListsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerId = useSelector((state) => state.user.selectedClient.id);
  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  const [index, setIndex] = useState(0);
  const [isAddShoppingListOpen, setIsAddShoppingListOpen] = useState(false);
  const [isDeleteShoppingListOpen, setIsDeleteShoppingListOpen] =
    useState(false);

  const addShoppingListProductsToCart = () => {
    addProductsToCart(shoppingLists?.[index]?.products, customerId)
      .then((cart) => {
        dispatch(updateCart(cart));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            console.error(
              `Error while adding product to cart at ProductListCard: ${msg}`
            );
          }
        }
      );
  };

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box marginBottom={4}>
        <MyAccountTitle>Listas de compras</MyAccountTitle>
        <Typography variant='small'>
          Crie listas de compras e associe produtos separadamente.
        </Typography>
      </Box>

      {shoppingLists.length !== 0 ? (
        <Box>
          <FormControl
            sx={{
              width: { xs: '100%', md: '30%' },
            }}
          >
            <InputLabel>Lista selecionada</InputLabel>
            <Select
              label='Lista selecionada'
              value={index}
              onChange={(e) => setIndex(e.target.value)}
            >
              {shoppingLists.map(({ name, _id }, index) => (
                <MenuItem
                  key={`shopping-list-${_id}`}
                  value={index}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ShoppingListProducts
            index={index}
            shoppingLists={shoppingLists}
          />
        </Box>
      ) : (
        <Typography>Não existem Listas de Compras criadas.</Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: { xs: 1.5, sm: 2 },
          marginTop: 3,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'initial' },
        }}
      >
        {shoppingLists.length !== 0 && (
          <Button
            variant='contained'
            title='Remover Lista de Compras'
            color='secondary'
            onClick={() => setIsDeleteShoppingListOpen(true)}
          >
            Remover Lista de Compras
          </Button>
        )}

        <Button
          variant='contained'
          color='secondary'
          title='Adicionar Lista de Compras'
          endIcon={<FiPlus size={14} />}
          onClick={() => setIsAddShoppingListOpen(true)}
        >
          Adicionar Lista de Compras
        </Button>

        {shoppingLists.length !== 0 &&
          shoppingLists?.[index]?.products.length !== 0 && (
            <Button
              variant='contained'
              title='Adicionar produtos ao carrinho'
              disabled
              onClick={() => addShoppingListProductsToCart()}
              sx={{
                marginLeft: { sm: 'auto' },
              }}
            >
              Adicionar produtos ao carrinho
            </Button>
          )}
      </Box>

      <CreateShoppingListModal
        isOpen={isAddShoppingListOpen}
        setIsOpen={setIsAddShoppingListOpen}
      />

      <DeleteShoppingListModal
        isOpen={isDeleteShoppingListOpen}
        setIsOpen={setIsDeleteShoppingListOpen}
        setIndex={setIndex}
        shoppingLists={shoppingLists}
      />
    </Box>
  );
};

export default ShoppingListsPage;
