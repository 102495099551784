import { Box, Button, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  LOGIN_FORM_TITLE,
  LOGIN_PASS_RECOVERY_TEXT,
  LOGIN_REGISTER_TEXT,
} from '../../variables';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Box
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography sx={{ textAlign: 'center', fontWeight: 700 }}>
        {LOGIN_FORM_TITLE}
      </Typography>

      <LoginForm />

      <Typography
        component={Link}
        to='recuperar-palavra-passe'
        variant='caption'
        title={LOGIN_PASS_RECOVERY_TEXT}
        sx={{ fontWeight: 500, textAlign: 'center' }}
      >
        {LOGIN_PASS_RECOVERY_TEXT}
      </Typography>

      <Divider sx={{ width: '100%', marginY: 2 }} />

      <Typography sx={{ textAlign: 'center' }}>
        {LOGIN_REGISTER_TEXT}
      </Typography>

      <Button
        component={Link}
        to='registo-utilizador'
        variant='contained'
        color='secondary'
        title='Novo registo'
      >
        Novo registo
      </Button>
    </Box>
  );
};

export default Login;
