import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../api/products';
// import { addProductToUserHistory } from '../api/user';
import Loading from '../components/Loading';
import ProductDetails from '../components/ProductPage/ProductDetails/ProductDetails';
import ProductImages from '../components/ProductPage/ProductImages';
import ProductRelated from '../components/ProductPage/ProductRelated';
import ProductTabs from '../components/ProductPage/ProductTabs/ProductTabs';
import kebabCase from 'lodash/kebabCase';
import { MainContainer } from '../styles';
import { setCollectionTheme } from '../state/userSlice';

const ProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productSlug } = useParams();

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => state.user);
  const { role: userRole, collectionTheme } = user;
  // const customerId = useSelector(
  //   (state) => state.user.selectedClient.id || state.user.id
  // );

  const customerId = useSelector((state) => state.user.sessionId);
  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken || true;
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getProduct(productSlug)
      .then((newProduct) => {
        setProduct(newProduct);

        if (userRole === 'customer') {
          // addProductToUserHistory(newProduct.reference, customerId);
        }

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSlug]);

  //  product collection theme functionality
  useEffect(() => {
    if (!product || !product.collection) return;
    const productCollection = kebabCase(product.collection);

    let collectionName = '';
    switch (productCollection) {
      case 'home':
        collectionName = 'home';
        break;
      case 'religiosa':
        collectionName = 'religious';
        break;
      case 'floral':
        collectionName = 'floral';
        break;
      case 'profissional':
        collectionName = 'professional';
        break;
      default:
        break;
    }

    if (collectionName === collectionTheme) return;

    dispatch(setCollectionTheme(collectionName));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (isLoading) {
    return <Loading />;
  }

  if (!product) {
    return null;
  }

  const images = product?.images;
  const { related, params, datasheet, video } = product;

  const availableParams = params.filter(
    (param) => param.value && param.value !== 'undefined'
  );

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: MOBILE_DEVICES && 'column',
              }}
              gap={{ xs: 4, md: 6 }}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  margin: { xs: '0 auto', md: '0' },
                }}
              >
                <ProductImages images={images} />
              </Box>

              <ProductDetails
                product={product}
                userIsLogged={userIsLogged}
                customerId={customerId}
              />
            </Box>

            <ProductTabs
              video={video}
              params={availableParams}
              datasheet={datasheet}
            />

            {related && <ProductRelated related={related} />}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductPage;
