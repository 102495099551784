import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  styled,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import { COMPANY_INFO, PAGES_SLUGS } from '../../variables';

const StyledAccordion = styled(Accordion)(() => ({
  color: 'white',
  borderBottom: '1px solid rgba(255, 255, 255, 0.40)',
}));

const StyledLabel = styled(Typography)(() => ({
  fontWeight: 600,
  color: 'inherit',
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: 'inherit',
  textDecoration: 'none',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  '&:hover': {
    color: 'inherit',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(3)}`,
}));

const FooterMobileAccordion = () => {
  const {
    address: { street, zipCode, city },
    mobile,
    phone,
    fax,
    email,
  } = COMPANY_INFO;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ marginTop: 2.5 }}>
      <StyledAccordion
        disableGutters
        expanded={expanded === 'aboutUs'}
        onChange={handleChange('aboutUs')}
      >
        <StyledAccordionSummary
          expandIcon={expanded === 'aboutUs' ? <FiMinus /> : <FiPlus />}
        >
          <StyledLabel>{COMPANY_INFO.name}</StyledLabel>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <StyledLink
            variant='link'
            component={RouterLink}
            to={`/${PAGES_SLUGS.institutional.aboutUs}`}
            title='Quem Somos'
          >
            Quem Somos
          </StyledLink>

          <StyledLink
            variant='link'
            component={RouterLink}
            to={PAGES_SLUGS.contacts}
            title='Contactos'
          >
            Contactos
          </StyledLink>
        </StyledAccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        disableGutters
        expanded={expanded === 'customerSupport'}
        onChange={handleChange('customerSupport')}
      >
        <StyledAccordionSummary
          expandIcon={expanded === 'customerSupport' ? <FiMinus /> : <FiPlus />}
        >
          <StyledLabel>Informações</StyledLabel>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <StyledLink
            variant='link'
            marginBottom={0.5}
            component={RouterLink}
            to={`/${PAGES_SLUGS.institutional.termsAndConditions}`}
            title='Termos e Condições'
          >
            Termos e Condições
          </StyledLink>

          <StyledLink
            variant='link'
            marginBottom={0.5}
            component={RouterLink}
            to={`/${PAGES_SLUGS.institutional.privacyPolicy}`}
            title='Política de Privacidade'
          >
            Política de Privacidade
          </StyledLink>

          <StyledLink
            variant='link'
            marginBottom={0.5}
            component={RouterLink}
            to={`/${PAGES_SLUGS.institutional.alternativeDisputeResolution}`}
            title='Resolução Alternativa de Litígios'
          >
            Resolução Alternativa de Litígios
          </StyledLink>

          <StyledLink
            variant='link'
            component={RouterLink}
            to={`/${PAGES_SLUGS.institutional.faqs}`}
            title='Faqs'
          >
            Faqs
          </StyledLink>

          <StyledLink
            component={Link}
            href='https://www.livroreclamacoes.pt/Inicio/'
            target='_blank'
            rel='noreferrer'
            underline='none'
          >
            Livro de Reclamações
          </StyledLink>
        </StyledAccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        disableGutters
        expanded={expanded === 'contactUs'}
        onChange={handleChange('contactUs')}
      >
        <StyledAccordionSummary
          expandIcon={expanded === 'contactUs' ? <FiMinus /> : <FiPlus />}
        >
          <StyledLabel>Contacte-nos</StyledLabel>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Typography sx={{ color: 'white', fontWeight: 300 }}>
            {street} <br /> {zipCode}
            <br />
            {city}
          </Typography>

          {mobile.value && (
            <Box sx={{ marginTop: 1.5 }}>
              <Typography
                component={'a'}
                href={`tel:${mobile.value}`}
                title={mobile.label}
                sx={{
                  display: 'block',
                  color: 'white',
                  fontWeight: 300,
                  textDecoration: 'none',
                }}
              >
                <strong>Tel. </strong>
                {mobile.label}
              </Typography>
            </Box>
          )}

          {phone.value && (
            <Box sx={{ marginTop: 0.5 }}>
              <Typography
                component={'a'}
                href={`tel:${phone.value}`}
                title={phone.label}
                sx={{
                  display: 'block',
                  color: 'white',
                  fontWeight: 300,
                  textDecoration: 'none',
                }}
              >
                <strong>Telf. </strong>
                {phone.label}
              </Typography>
              <Typography
                variant='small'
                sx={{
                  color: 'white',
                  fontWeight: 300,
                }}
              >
                (Chamada para a rede fixa nacional)
              </Typography>
            </Box>
          )}

          {fax.value && (
            <Typography
              sx={{ marginTop: 1.5, color: 'white', fontWeight: 300 }}
            >
              <strong>Fax. </strong>
              {fax.label}
            </Typography>
          )}

          {email && (
            <Typography
              component={'a'}
              href={`mailto:${email}`}
              title={email}
              sx={{
                display: 'block',
                color: 'white',
                textDecoration: 'none',
                fontWeight: 300,
                marginTop: 1.5,
              }}
            >
              <strong>E.</strong> {email}
            </Typography>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FooterMobileAccordion;
