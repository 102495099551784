import { Box, Typography } from '@mui/material';
// import { CUSTOM_THEME_COLORS } from '../../../variables';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { BadgeDiscount } from '../../../styles';

const ProductPrices = ({ product }) => {
  const { price, boxPrice, saleAmount, salePrice, boxSalePrice } = product;
  const customerDiscount = useSelector((state) => state.user.customer_discount);

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: 3,
        gap: { xs: 5, md: 8 },
      }}
    >
      <Box>
        <Typography
          sx={{
            marginBottom: 1,
            fontWeight: 600,
          }}
        >
          Preço
        </Typography>

        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography sx={{ fontWeight: 700 }}>un</Typography>
            <Typography>{formatCurrency(price)}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography sx={{ fontWeight: 700 }}>cx</Typography>
            <Typography>{formatCurrency(boxPrice)}</Typography>
          </Box>
        </Box>

        {/* {onSale ? (
            <Box
              sx={{
                display: 'flex',
                gap: 1.5,
              }}
            >
              <Typography
                sx={{
                  textDecoration: 'line-through',
                }}
              >
                {formatCurrency(price)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: CUSTOM_THEME_COLORS.general.primary,
                }}
              >
                {formatCurrency(salePrice)}
              </Typography>
            </Box>
          ) : (
            <Typography
            >
              {formatCurrency(price)}
            </Typography>
          )} */}
      </Box>

      {(saleAmount || customerDiscount) && (
        <Box>
          <Typography
            sx={{
              marginBottom: 1,
              fontWeight: 600,
            }}
          >
            {saleAmount ? 'Desconto' : 'Desconto cliente'}
          </Typography>

          <Box>
            <BadgeDiscount>{`${
              saleAmount || customerDiscount
            }%`}</BadgeDiscount>
          </Box>
        </Box>
      )}

      {/* {itemsPerBox !== 0 && ( */}
      <Box>
        <Typography
          sx={{
            marginBottom: 1,
            fontWeight: 600,
          }}
        >
          Preço Líquido
        </Typography>

        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography sx={{ fontWeight: 700 }}>un</Typography>
            <Typography>{formatCurrency(salePrice)}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography sx={{ fontWeight: 700 }}>cx</Typography>
            <Typography>{formatCurrency(boxSalePrice)}</Typography>
          </Box>
        </Box>

        {/* <Box>
            {onSale ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                }}
              >
                <Typography
                  sx={{
                    textDecoration: 'line-through',
                  }}
                >
                  {formatCurrency(boxPrice)}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: CUSTOM_THEME_COLORS.general.primary,
                  }}
                >
                  {formatCurrency(boxSalePrice)}
                </Typography>
              </Box>
            ) : (
              <Typography>
                {formatCurrency(boxPrice)}
              </Typography>
            )}
          </Box> */}
      </Box>
      {/* )} */}
    </Box>
  );
};

export default ProductPrices;
