import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AddressesForm from './AddressesForm';
import NewAddressModal from './NewAddressModal';
import { MyAccountTitle } from '../../../styles';

const AccountAddresses = () => {
  const addresses = useSelector((state) => state.user.addresses);

  const [isCreateNewAddressOpen, setIsCreateNewAddressOpen] = useState(false);

  if (!addresses) return null;

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          marginBottom: 4,
        }}
      >
        <MyAccountTitle>Moradas</MyAccountTitle>
        <Typography variant='small'>Escolha a sua morada e edite-a.</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'initial' },
        }}
      >
        <AddressesForm
          openNewAddressModal={setIsCreateNewAddressOpen}
          addresses={addresses}
        />
      </Box>

      <NewAddressModal
        isOpen={isCreateNewAddressOpen}
        setIsOpen={setIsCreateNewAddressOpen}
      />
    </Box>
  );
};

export default AccountAddresses;
