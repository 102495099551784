import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import { ProductCardContext } from '../contexts/ProductCardContext';
import Modal, { ModalActions, ModalContent, ModalTitle } from './Modal';
import ProductVariants from './ProductPage/ProductVariants/ProductVariants';
import ProductVariantsDetails from './ProductPage/ProductVariants/ProductVariantsDetails';

const ProductVariantsModal = ({
  isVariationsModalOpen,
  setIsVariationsModalOpen,
  productsForCart,
  setProductsForCart,
}) => {
  const { productVariants, addToCart } = useContext(ProductCardContext);
  const addToCartDisabled = !!productsForCart.totalUnits;
  const variationName = productVariants?.[0]?.variationField;

  return (
    <Modal
      isOpened={isVariationsModalOpen}
      onClose={() => {
        setIsVariationsModalOpen(false);
        //  reset products
        setProductsForCart((prevState) => ({ ...prevState, products: [] }));
      }}
    >
      <ModalTitle>Pedido Rápido</ModalTitle>
      <ModalContent>
        <ProductVariants
          variants={productVariants}
          variationName={variationName}
          productsForCart={productsForCart}
          setProductsForCart={setProductsForCart}
        />
      </ModalContent>
      <ModalActions
        sx={{
          justifyContent: 'space-between',
          marginTop: 0,
        }}
      >
        <ProductVariantsDetails
          productsForCart={productsForCart}
          setProductsForCart={setProductsForCart}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'flex-end',
          }}
        >
          <Button
            title='Cancelar'
            variant='contained'
            color='secondary'
            onClick={() => setIsVariationsModalOpen(false)}
          >
            Cancelar
          </Button>

          <Button
            title='Adicionar ao pedido'
            variant='contained'
            disabled={!addToCartDisabled}
            onClick={() => addToCart()}
          >
            Adicionar ao pedido
          </Button>
        </Box>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(ProductVariantsModal);
