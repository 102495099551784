import { styled } from '@mui/material';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper } from 'swiper/react';
import { TRANSITION_DURATION } from '../../variables';

const SliderStyled = styled(Swiper)(({ theme }) => ({
  '&.swiper': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '&.swiper .swiper-button-prev, &.swiper .swiper-button-next': {
    display: 'none',
    width: '50px',
    height: '100%',
    top: 0,
    marginTop: 0,
    color: 'white',
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
  },
  '.swiper-button-prev.swiper-button-lock, .swiper-button-next.swiper-button-lock':
    {
      display: 'none !important',
    },
  '&.swiper .swiper-button-prev:hover, &.swiper .swiper-button-next:hover': {
    opacity: '0.75',
  },
  '.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled':
    {
      opacity: '0.5',
    },
  [theme.breakpoints.up('md')]: {
    '&.swiper .swiper-button-prev, &.swiper .swiper-button-next': {
      display: 'flex',
    },
  },
  [theme.breakpoints.up('xl')]: {
    '&.swiper .swiper-button-prev, &.swiper .swiper-button-next': {
      width: '75px',
    },
  },
}));

const Slider = (props) => {
  const { children } = props;
  return (
    <SliderStyled
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 5000,
      }}
      {...props}
    >
      {children}
    </SliderStyled>
  );
};

export default Slider;
