import { Box, Button, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CartProgressIndicator from '../components/Cart/CartProgressIndicator';
import CartSummary from '../components/Cart/FirstStepSummary/CartSummary';
import OrderConfirmation from '../components/Cart/OrderConfirmation';
// import OrderDetails from '../components/Cart/SecondStepOrderDetails/OrderDetails';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import BudgetRequest from '../components/Cart/BudgetRequest/BudgetRequest';
import { MainContainer } from '../styles';

const CartPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const state = useSelector((state) => state);
  const { cart: cartObject, user } = state;
  const { cart, total } = cartObject;
  const { sessionId, _selectedClient, role } = user;

  function handleActiveStep(step) {
    const element = document.querySelector('.app');

    element.scrollTo({ top: 0, behavior: 'smooth' });

    setActiveStep(step);
  }

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            {cart.length === 0 && activeStep !== 2 ? (
              <Box sx={{ textAlign: 'center' }}>
                <Typography>
                  Adicione produtos e as respetivas quantidades ao seu pedido de orçamento.
                </Typography>
                <Button
                  title='Voltar à homepage'
                  variant='contained'
                  color='secondary'
                  component={Link}
                  to='/'
                  sx={{ marginTop: 2 }}
                >
                  Voltar à homepage
                </Button>
              </Box>
            ) : (
              <>
                <CartProgressIndicator activeStep={activeStep} />

                {activeStep === 0 && (
                  <CartSummary
                    setActiveStep={handleActiveStep}
                    cartItems={cart}
                    cartTotal={total}
                    selectedClient={sessionId}
                    role={role}
                  />
                )}

                {activeStep === 1 && (
                  // <OrderDetails
                  //   setActiveStep={handleActiveStep}
                  //   cart={cartObject}
                  // />
                  <BudgetRequest
                    setActiveStep={handleActiveStep}
                    cart={cartObject}
                  />
                )}

                {activeStep === 2 && <OrderConfirmation />}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default CartPage;
