import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCart, setCartTotal } from '../../state/cartSlice';
import { StyledTextButton } from '../../styles';

const OrderConfirmation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //  reset total cart
    dispatch(setCartTotal(0));
    //  reset cart items
    dispatch(setCart([]));
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: { xs: 5, md: 0 },
      }}
    >
      <IconContext.Provider value={{ style: { color: '#4FD290' } }}>
        <BsCheckCircle size={64} />
      </IconContext.Provider>

      <Typography sx={{ fontWeight: 700, marginTop: { xs: 2, md: 4 } }}>
        O seu pedido foi finalizado com sucesso.
      </Typography>

      <Typography sx={{ marginTop: { xs: 1 } }}>
        De seguida, irá receber um email com todos os detalhes do seu pedido de orçamento.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginY: { xs: 2, md: 4 },
        }}
      >
        <StyledTextButton
          title='Voltar à homepage'
          variant='contained'
          color='secondary'
          component={Link}
          to='/'
        >
          Voltar à homepage
        </StyledTextButton>

        {/* <StyledTextButton
          title='Ver encomendas'
          variant='contained'
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.orders}`}
        >
          Ver Encomendas
        </StyledTextButton> */}
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
