import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',

  initialState: {
    id: '',
    cart: [],
    total: 0,
    discount: 0,
    shipping: '',
    deliveryAtAddress: '',
    deliveryAtStore: '',
    shippingNotes: '',
  },

  reducers: {
    setCartId: (state, action) => {
      state.id = action.payload;
    },

    // real use case: reset cart after order completion
    setCart: (state, action) => {
      state.cart = action.payload;
    },

    setCartTotal: (state, action) => {
      state.total = action.payload;
    },

    setDiscount: (state, action) => {
      state.discount = action.payload;
    },

    updateCart: (state, action) => {
      state.cart = [...action.payload];
    },

    // Removes variant from cart
    removeFromCart: (state, action) => {
      const { reference } = action.payload;

      const cart = state.cart;

      const foundProduct = cart.find(({ reference: newReference }) => newReference === reference);

      const newQuantity = 0;

      const newProduct = { ...foundProduct, quantity: newQuantity };

      const updatedCart = cart.map((product) => (product.reference === reference ? newProduct : product));

      const newCart = updatedCart.filter(({ quantity }) => quantity !== 0);

      state.cart = [...newCart];
    },

    // Removes quantity, or product, from cart
    removeQuantityFromCartItem: (state, action) => {
      state.cart = [...action.payload];
      // const oldCart = state.cart;

      // const products = action.payload;

      // const updatedProducts = oldCart.map((product) => {
      //   const foundProduct = products.find(
      //     ({ reference }) => reference === product.reference
      //   );

      //   const newProduct = { ...product };

      //   const newQuantity = foundProduct?.quantity || 0;

      //   newProduct.quantity -= newQuantity || 0;

      //   return newProduct;
      // });

      // const updatedCart = updatedProducts.filter(
      //   ({ quantity }) => quantity !== 0
      // );

      // state.cart = [...updatedCart];
    },
  },
});

export const { setCartId, setCart, setCartTotal, setDiscount, updateCart, removeFromCart, removeQuantityFromCartItem } =
  cartSlice.actions;

export default cartSlice.reducer;
