import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCurrency } from '../constants/utils';
import { splitString } from './splitString';

// define a generatePDF function that accepts a products argument
const generatePDF = (order) => {
  const { reference, expeditionType, deliveryDate, date, total, items, billingAddress, shippingPrice, totalDiscount } =
    order;
  let totalWithoutTaxes = 0;
  const pageBeginning = 15;
  const pageEnding = 195;
  const pageMiddle = 115;

  let billingAddressStreetIsSplitted = false;
  let billingAddressStreet = billingAddress?.street;
  const billingAddressStreetLength = billingAddressStreet.length;

  if (billingAddressStreetLength >= 40) {
    billingAddressStreetIsSplitted = true;
    billingAddressStreet = splitString(billingAddressStreet, 40);
  }

  const calculateSavedPrice = (price, discount) => {
    return price * (discount / 100);
  };

  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles
  const tableColumn = ['Designação', 'Quantidade', 'Preço unitário', 'Desconto'];
  // define an empty array of rows
  const tableRows = [];

  // for each product pass all its data into an array
  items.map((product) => {
    const discountCalc = product?.discount !== 0 && calculateSavedPrice(product?.original_discountPrice, product?.discount);
    const discountValue =
      product?.discount !== 0
        ? `${formatCurrency(discountCalc)} (${product?.discount}%)`
        : formatCurrency(product?.discount);
    const productData = [product?.title, product?.quantity, formatCurrency(product?.discountPrice), discountValue];
    totalWithoutTaxes += product?.discountPrice * product?.quantity;
    return tableRows.push(productData);
  });

  const totalOrder = formatCurrency(total);
  const shipping = formatCurrency(shippingPrice);
  const discount = formatCurrency(totalDiscount);
  const taxesAmout = formatCurrency(totalWithoutTaxes * 0.23);

  //  GLOBAL STYLES
  doc.setFontSize(10);
  doc.setFont(undefined, 'bold');
  doc.text('Imporvelas', pageBeginning, 15);
  doc.setFont(undefined, 'normal');
  doc.text('Av. de Trezeste Armazem 04 Entreposto 02', pageBeginning, 21);
  doc.text('4709-001 Braga', pageBeginning, 27);
  doc.text('NIF: 508565103', pageBeginning, 33);

  doc.setFont(undefined, 'bold');
  doc.text(billingAddress.name, pageMiddle, 22);
  doc.setFont(undefined, 'normal');
  doc.text(`${billingAddressStreet}`, pageMiddle, 28);
  doc.text(`${billingAddress.zip_code} ${billingAddress.city}`, pageMiddle, billingAddressStreetIsSplitted ? 38 : 34);
  doc.text(billingAddress.country, pageMiddle, billingAddressStreetIsSplitted ? 44 : 40);

  doc.setFont(undefined, 'bold');
  doc.text(`Encomenda nº: ${reference}`, pageBeginning, 56);
  doc.setFont(undefined, 'normal');
  doc.line(pageBeginning, 58, pageEnding, 58);
  doc.setFontSize(9);
  doc.text(`Data: ${date}`, pageBeginning, 63);
  doc.text(`Data entrega: ${deliveryDate}`, 65, 63);
  doc.text(`Método de envio: ${expeditionType}`, 125, 63);
  doc.setFontSize(10);

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    theme: 'plain',
    startY: 74,
    styles: { fontSize: 9 },
    headStyles: { fillColor: '#f5f5f8' },
  });

  doc.autoTable({
    body: [
      ['Subtotal', formatCurrency(totalWithoutTaxes)],
      ['Envio', shipping],
      ['Desconto', discount],
      ['Total de I.V.A.', taxesAmout],
      [
        { content: 'TOTAL', styles: { fontStyle: 'bold' } },
        { content: totalOrder, styles: { fontStyle: 'bold' } },
      ],
    ],
    theme: 'plain',
    styles: { fillColor: '#f5f5f8' },
    margin: { left: pageMiddle },
    startY: doc.autoTableEndPosY() + 50,
    tableWidth: pageEnding - pageMiddle,
    padding: { top: 2, right: 4, bottom: 2, left: 4 },
  });

  // we define the name of our PDF file.
  doc.save(`encomenda_${date}_${Math.floor(Date.now() / 1000)}.pdf`);
  // doc.output('dataurlnewwindow'); //to check pdf generate
};

export default generatePDF;
