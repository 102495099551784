import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../api';
import { mapProduct } from '../api/mapApiInterface';
import DealsSection from '../components/HomePage/DealsSection';
import HighlightedProducts from '../components/HomePage/HighlightedProducts';
import MainBanner from '../components/HomePage/MainBanner';
import NewProducts from '../components/HomePage/NewProducts';
import SecondaryBanner from '../components/HomePage/SecondaryBanner';
import { MainContainer } from '../styles';

const HomePage = () => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.cart);

  const [mainBanner, _setMainBanner] = useState([
    {
      title: 'Bem-vindo à nossa Plataforma B2B',
      description:
        '<p>Brevemente disponível a versão final com novas funcionalidades:</p><ul><li>Área Cliente;</li><li>Compras Online / Encomendas;</li><li>E muitas outras opções...</li></ul><br><p>Faça já o seu pedido de orçamento!</p>',
      buttonTitle: 'Pedir orçamento',
      buttonUrl: '/religiosa',
      image: {
        desktop: `${process.env.PUBLIC_URL}/desktop_home_banner_1.jpg`,
        mobile: `${process.env.PUBLIC_URL}/mobile_home_banner_1.jpg`,
      },
    },
  ]);
  const [campaigns, setCampaigns] = useState(null);
  const [highlightedProducts, setHighlightedProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);

  useEffect(() => {
    //  get homepage content
    getPage('homepage')
      .then((page) => {
        const {
          _mainBanner,
          campaigns,
          products: { highlights, news },
        } = page;

        // setMainBanner(mainBanner);
        setCampaigns(campaigns);

        //  get highlighted products
        setHighlightedProducts(
          highlights.map((product) => mapProduct(product))
        );

        //  get new products
        setNewProducts(news.map((product) => mapProduct(product)));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [navigate, cart]);

  return (
    <MainContainer
      sx={{
        paddingY: { xs: 0 },
      }}
    >
      {mainBanner && <MainBanner mainBanner={mainBanner} />}
      {!!highlightedProducts.length && (
        <HighlightedProducts products={highlightedProducts} />
      )}
      {campaigns && <SecondaryBanner data={campaigns} />}
      {!!newProducts.length && <NewProducts products={newProducts} />}
      <DealsSection />
    </MainContainer>
  );
};

export default HomePage;
