import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainContainer } from '../styles';
import Grid from '@mui/material/Unstable_Grid2';

const TermsPage = ({ props }) => {
  const [pageContent, setPageContent] = useState(props);
  const { title, description } = pageContent;

  useEffect(() => {
    setPageContent(props);
    return () => {
      setPageContent();
    };
  }, [props]);

  return (
    <MainContainer>
      <Container>
        <Grid
          container
        >
          {title && (
            <Grid xs={12}>
              <Typography
                variant='h1'
                sx={{ textAlign: 'center', marginBottom: 3 }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {description && (
            <Grid container>
              <Grid
                xs={12}
                md={10}
                mdOffset={1}
                lg={8}
                lgOffset={2}
              >
                <Typography dangerouslySetInnerHTML={{ __html: description }} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default TermsPage;
