import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SETTINGS } from '../../../variables';
import Quantity from '../../Quantity/Quantity';
import AddShoppingListButton from '../../ShoppingLists/AddShoppingListButton';
import ProductVariantsDetails from '../ProductVariants/ProductVariantsDetails';

const ProductCheckout = ({
  product,
  hasVariants,
  addToCart,
  productsForCart,
  setProductsForCart,
}) => {
  const addToCartDisabled = hasVariants && productsForCart.totalUnits === 0;

  const [updatedProductQuantity, setUpdatedProductQuantity] = useState({
    // SIMPLE PRODUCT
    ...product,
    quantity: {
      ...product.quantity,
      box: 1,
    },
  });

  useEffect(() => {
    if (hasVariants) return;

    //  IF 'simple product' » ADD/UPDATE 'simple product' inside array
    setProductsForCart((prevState) => ({
      ...prevState,
      products: [updatedProductQuantity],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: hasVariants ? 'space-between' : 'flex-start',
          gap: { xs: 5, md: 7 },
          width: '100%',
          marginTop: 4,
        }}
      >
        {hasVariants ? (
          <ProductVariantsDetails
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
        ) : (
          <Box>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: 600,
              }}
            >
              Quantidade (caixa)
            </Typography>

            <Quantity
              product={updatedProductQuantity}
              setUpdatedProductQuantity={setUpdatedProductQuantity}
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'inline-flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'center', md: 'initial' },
            alignItems: {
              xs: 'flex-start',
              sm: hasVariants ? 'flex-start' : 'flex-end',
            },
            gap: 2,
          }}
        >
          {SETTINGS.functionalities.shoppingList && (
            <AddShoppingListButton productId={product.id} />
          )}

          <Button
            variant='contained'
            title='Adicionar ao pedido'
            onClick={() => addToCart()}
            disabled={!!addToCartDisabled}
          >
            Adicionar ao pedido
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProductCheckout;
