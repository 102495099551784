import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../constants/utils';
import { imageOnError } from '../helpers';
import { ProductCardContext } from '../contexts/ProductCardContext';
import { BadgeDiscount, IconButton } from '../styles';
import {
  CUSTOM_THEME_COLORS,
  PAGES_SLUGS,
  PLACEHOLDER_IMAGE,
  TRANSITION_DURATION,
} from '../variables';
import ProductBadge from './ProductBadge';
import ProductStock from './ProductStock';

const ProductCard = ({
  product,
  showPrice = true,
  showStockAvailability = true,
}) => {
  const theme = useTheme();
  const { addToCart, setProductVariants, setIsVariationsModalOpen } =
    useContext(ProductCardContext);
  let variantsSortedDescByPrice = [];
  const {
    id,
    title,
    saleAmount,
    onSaleBox,
    boxPrice,
    boxSalePrice,
    stockPerBox,
    images,
    badge,
    variants,
    reference,
  } = product;

  const hasVariants = !!variants.length;

  if (hasVariants) {
    variantsSortedDescByPrice = [...variants].sort((a, b) =>
      a.onSaleBox ? a.boxSalePrice - b.boxSalePrice : a.boxPrice - b.boxPrice
    )[0];
  }

  return (
    <Card
      className='product-card'
      sx={{
        minWidth: '130px',
        maxWidth: '300px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingY: 2,
        '&:hover': {
          '.productCardTitle': {
            color: CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary,
          },
          '.productCardActions': {
            opacity: 1,
          },
        },
      }}
      component={Link}
      to={`${PAGES_SLUGS.productDetail}/${id}`}
      title={title}
    >
      <Box
        className='productCardImage'
        sx={{
          position: 'relative',
          transition: `transform ${TRANSITION_DURATION}ms ease`,
        }}
      >
        {badge && <ProductBadge badge={badge} />}
        <CardMedia
          component='img'
          image={images.length !== 0 ? images?.[0] : PLACEHOLDER_IMAGE}
          onError={imageOnError}
          alt={title}
          sx={{
            maxWidth: '100%',
            objectFit: 'contain',
            aspectRatio: '1',
            borderRadius: theme.shape.borderRadius / 10,
          }}
        />
        <Box
          className='productCardActions'
          sx={{
            width: '100%',
            position: 'absolute',
            left: '0',
            bottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            opacity: 0,
            transition: `opacity ${TRANSITION_DURATION}ms ease`,
          }}
        >
          <IconButton
            title='Adicionar ao pedido'
            variant='contained'
            sx={{
              minWidth: 'initial',
            }}
            onClick={(e) => {
              e.preventDefault();
              return hasVariants
                ? (setProductVariants(variants), setIsVariationsModalOpen(true))
                : addToCart(product);
            }}
          >
            <TbShoppingCartPlus size={18} />
          </IconButton>
        </Box>
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: `${theme.spacing(1.5)} 0 0 0 !important`,
        }}
      >
        <Typography
          className='productCardTitle'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            transition: `color ${TRANSITION_DURATION}ms ease`,
          }}
          fontWeight={600}
        >
          {title}
        </Typography>

        {reference && (
          <Typography
            sx={{
              fontSize: '0.85rem',
              color: CUSTOM_THEME_COLORS.general.darkGray,
              marginTop: 0.5,
            }}
          >{`Ref: ${reference}`}</Typography>
        )}

        <Box
          sx={{
            marginTop: 'auto',
            paddingTop: showPrice || stockPerBox <= 0 ? 1 : null,
          }}
        >
          {showPrice && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                marginBottom: 1,
              }}
            >
              {hasVariants && (
                <Typography
                  variant='xsmall'
                  sx={{
                    lineHeight: 1,
                    fontWeight: 600,
                  }}
                >
                  desde
                </Typography>
              )}

              <Typography
                sx={{
                  textDecoration:
                    (hasVariants
                      ? variantsSortedDescByPrice.onSaleBox
                      : onSaleBox) && 'line-through',
                }}
              >
                {formatCurrency(
                  hasVariants ? variantsSortedDescByPrice.boxPrice : boxPrice
                )}
              </Typography>

              {(hasVariants
                ? variantsSortedDescByPrice.onSaleBox
                : onSaleBox) && (
                <>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: 'danger.main',
                    }}
                  >
                    {formatCurrency(
                      hasVariants
                        ? variantsSortedDescByPrice.boxSalePrice
                        : boxSalePrice
                    )}
                  </Typography>
                  <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                </>
              )}
            </Box>
          )}

          {showStockAvailability && <ProductStock stock={stockPerBox} />}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
