import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { COMPANY_INFO } from '../../variables';

const ContactUsContent = styled(Typography)(() => ({
  display: 'block',
  color: 'white',
  fontWeight: 300,
  textDecoration: 'none',
}));

const ContactUs = () => {
  const {
    address: { street, zipCode, city },
    mobile,
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h4'
        marginBottom={2}
        sx={{
          color: 'white',
        }}
      >
        Contacte-nos
      </Typography>

      <ContactUsContent sx={{ color: 'white', fontWeight: 300 }}>
        {street} <br /> {zipCode}
        <br />
        {city}
      </ContactUsContent>

      {mobile.value && (
        <Box sx={{ marginTop: 1.5 }}>
          <ContactUsContent
            component={'a'}
            href={`tel:${mobile.value}`}
            title={mobile.label}
            sx={{
              display: 'block',
              color: 'white',
              fontWeight: 300,
              textDecoration: 'none',
            }}
          >
            <strong>Tel. </strong>
            {mobile.label}
          </ContactUsContent>
        </Box>
      )}

      {phone.value && (
        <Box sx={{ marginTop: 1.5 }}>
          <ContactUsContent
            component={'a'}
            href={`tel:${phone.value}`}
            title={phone.label}
            sx={{
              display: 'block',
              color: 'white',
              fontWeight: 300,
              textDecoration: 'none',
            }}
          >
            <strong>Telf. </strong>
            {phone.label}
          </ContactUsContent>
          <Typography
            variant='small'
            sx={{
              color: 'white',
              fontWeight: 300,
            }}
          >
            (Chamada para a rede fixa nacional)
          </Typography>
        </Box>
      )}

      {fax.value && (
        <ContactUsContent
          sx={{ marginTop: 1.5, color: 'white', fontWeight: 300 }}
        >
          <strong>Fax. </strong>{fax.label}
        </ContactUsContent>
      )}

      {email && (
        <ContactUsContent
          component={'a'}
          href={`mailto:${email}`}
          title={email}
          sx={{
            display: 'block',
            color: 'white',
            textDecoration: 'none',
            fontWeight: 300,
            marginTop: 1.5,
          }}
        >
          <strong>E. </strong>{email}
        </ContactUsContent>
      )}
    </Grid>
  );
};

export default ContactUs;
