import { AppBar, Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_THEME_COLORS } from '../../variables';
import CompanyLogo from '../CompanyLogo';
import AccountLinks from './AccountLinks/AccountLinks';
import ClientSelector from './ClientSelector';
import NavBar from './NavBar/NavBar';
import SearchBar from './SearchBar';

const Header = () => {
  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));
  const role = useSelector((state) => state.user.role);

  return (
    <AppBar
      sx={{
        position: 'sticky',
        backgroundColor: 'white',
        border: 'none',
        borderBottom: {
          xs: `3px solid ${
            CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary
          }`,
          md: 'none',
        },
      }}
    >
      {role && role !== 'customer' && <ClientSelector />}
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingY: 1.5,
          }}
        >
          <CompanyLogo />
          {MOBILE_DEVICES ? (
            <>
              <AccountLinks />
              <SearchBar />
            </>
          ) : (
            <>
              <SearchBar />
              <AccountLinks />
            </>
          )}
        </Box>
      </Container>
      {!MOBILE_DEVICES && <NavBar />}
    </AppBar>
  );
};

export default Header;
