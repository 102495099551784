import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import React, { useState } from 'react';
import { formatCurrency } from '../../../constants/utils';
import { imageOnError } from '../../../helpers';
import { BadgeDiscount } from '../../../styles';
import { CUSTOM_THEME_COLORS, TRANSITION_DURATION } from '../../../variables';
import ImageZoomModal from '../../ImageZoomModal';
import Quantity from '../../Quantity/Quantity';

const ProductVariantsTable = ({
  variants,
  variationName,
  userIsLogged,
  setUpdatedProductQuantity,
}) => {
  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          size='small'
          id='product-variants'
        >
          <TableHead>
            <TableRow>
              <TableCell>Imagem</TableCell>
              <TableCell>Referência</TableCell>
              {variationName && <TableCell>{variationName}</TableCell>}
              <TableCell>Nome</TableCell>
              {userIsLogged && (
                <>
                  <TableCell>Quantidade (caixa)</TableCell>
                  <TableCell
                    sx={{
                      display: 'none',
                    }}
                  >
                    Preço (un/cx)
                  </TableCell>
                  <TableCell
                    sx={{
                      display: 'none',
                    }}
                  >
                    Desconto
                  </TableCell>
                  <TableCell
                    sx={{
                      display: 'none',
                    }}
                  >
                    Preço Líquido (un/cx)
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {variants.map((product) => {
              const {
                reference,
                images,
                title,
                onSale,
                onSaleBox,
                saleAmount,
                price,
                salePrice,
                boxPrice,
                boxSalePrice,
                variationField,
                params,
              } = product;

              const variationValue = params.find(
                (param) => kebabCase(param.title) === kebabCase(variationField)
              )?.value;

              return (
                <TableRow
                  key={`product-variants-${reference}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 'none' },
                  }}
                >
                  <TableCell>
                    <Button
                      title='Ver imagem'
                      onClick={() => {
                        setZoomImagePath(images[0]);
                        setModalIsOpen(true);
                      }}
                      sx={{
                        display: 'flex',
                        minWidth: 'initial',
                        padding: 0,
                        marginX: 'auto',
                        overflow: 'hidden',
                        borderRadius: theme.shape.borderRadius / 10,
                        border: `1px solid ${CUSTOM_THEME_COLORS.general.gray}`,
                        transition: `border-color ${TRANSITION_DURATION}ms ease`,
                        '&:hover': {
                          borderColor:
                            CUSTOM_THEME_COLORS[theme.custom.collectionTheme]
                              .primary,
                          cursor: 'zoom-in',
                        },
                      }}
                    >
                      <Box
                        component='img'
                        alt={title}
                        src={images[0]}
                        onError={(e) => {
                          imageOnError(e);
                        }}
                        sx={{
                          maxWidth: '48px',
                          aspectRatio: '1',
                          objectFit: 'contain',
                          border: `${theme.spacing(0.5)} solid white`,
                        }}
                      />
                    </Button>
                  </TableCell>
                  <TableCell>{reference}</TableCell>
                  {variationName && (
                    <TableCell>
                      {variationValue ? variationValue : '-'}
                    </TableCell>
                  )}
                  <TableCell>{title}</TableCell>
                  {userIsLogged && (
                    <>
                      <TableCell>
                        <Quantity
                          product={product}
                          setUpdatedProductQuantity={setUpdatedProductQuantity}
                          variant
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          display: 'none',
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: 700 }}
                            >
                              un
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(price)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: 700 }}
                            >
                              cx
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(boxPrice)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          display: 'none',
                        }}
                      >
                        {onSale || onSaleBox ? (
                          <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                        ) : (
                          <Box>-</Box>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: 'none',
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: 700 }}
                            >
                              un
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(salePrice)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: 700 }}
                            >
                              cx
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(boxSalePrice)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default React.memo(ProductVariantsTable);
