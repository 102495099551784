import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import capitalize from 'lodash/capitalize';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts } from '../api/products';
import ProductsList from '../components/ProductsList/ProductsList';
import ProductsListBanner from '../components/ProductsList/ProductsListBanner';
import ProductsListFilters from '../components/ProductsList/ProductsListFilters';
import ProductsListToolbar from '../components/ProductsList/ProductsListToolbar';
import { ProductsListContext } from '../contexts/ProductsListContext';
import { MainContainer } from '../styles';
import { NOT_FOUND_ROUTE } from '../variables';

const AllProducts = () => {
  const {
    products,
    setProducts,
    filters,
    setFilters,
    pagination,
    setPagination,
    setIsLoading,
    getProductsTrigger,
    setGetProductsTrigger,
  } = useContext(ProductsListContext);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const userState = useSelector((state) => state.user);
  const { collectionTheme } = userState;

  const navigate = useNavigate();
  const { collectionSlug } = useParams();

  const hasCollectionsBanner = collectionTheme !== 'general';

  useEffect(() => {
    if (!getProductsTrigger) return;
    setIsLoading(true);

    getProducts({
      searchParams: filters.active,
    })
      .then(({ displayedProducts, displayedFilters, productsTotal }) => {
        //  set products & products total
        setProducts({
          list: displayedProducts,
          total: productsTotal,
        });

        //  remove filters without options » sidebar filters
        //	remove 'collection' » sidebar filters
        //	IF 'categories' is present at url remove it » sidebar filters
        const filtersFiltered = displayedFilters.filter(
          (filter) => filter.options.length !== 0 && filter.id !== 'collection'
        );

        //  set filters
        setFilters((prevState) => ({
          ...prevState,
          list: filtersFiltered,
        }));

        //  rounds a number UP to the nearest integer
        setPagination((prevState) => ({
          ...prevState,
          total: Math.ceil(productsTotal / pagination.itemsPerPage),
        }));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
        if (status === 404) navigate(`/${NOT_FOUND_ROUTE}`);
        setIsLoading(false);
      });

    setGetProductsTrigger(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductsTrigger]);

  return (
    <MainContainer
      sx={{
        paddingTop: hasCollectionsBanner ? '0 !important' : null,
      }}
    >
      {hasCollectionsBanner && (
        <ProductsListBanner collectionTheme={collectionTheme} />
      )}
      <Container>
        <Grid container>
          {!MOBILE_DEVICES && (
            <Grid md={3}>
              {!!filters.list.length && <ProductsListFilters />}
            </Grid>
          )}
          <Grid
            md={8.5}
            mdOffset={0.5}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginBottom: { md: 3 },
                }}
              >
                <Typography
                  variant='h2'
                  sx={{ flex: 1, fontSize: '1.5rem' }}
                >
                  {!!collectionSlug && (
                    <>
                      {capitalize(collectionSlug)}
                      {products.total !== null && (
                        <Typography
                          variant='overline'
                          marginLeft={1}
                          sx={{
                            textTransform: 'initial',
                          }}
                        >
                          (
                          {`${products.total} ${
                            products.total === 1 ? 'produto' : 'produtos'
                          }`}
                          )
                        </Typography>
                      )}
                    </>
                  )}
                </Typography>
                <ProductsListToolbar />
              </Box>
              <ProductsList />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AllProducts;
