import { Badge, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FiBookmark, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../../api/login';
import { setCart, setCartTotal } from '../../../state/cartSlice';
import {
  setRole,
  setSelectedClient,
  setSellerInfo,
} from '../../../state/userSlice';
import { IconButton } from '../../../styles';
import { ACCOUNT_LINKS, PAGES_SLUGS, SETTINGS } from '../../../variables';
import MenuMobile from '../MenuMobile';
import UserMenu from './UserMenu';

const AccountLinks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart?.cart);
  const userName = user.personalInfo.name;
  const role = user.role;

  const userRef = useRef(null);
  const [cookie, _setCookie, removeCookie] = useCookies(['userToken']);
  const hasLogin = cookie.userToken;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  function handleLogout() {
    setIsMenuOpen(false);
    setIsLoading(true);

    logout().then(() => {
      //  remove user token
      removeCookie('userToken', { path: '/' });
      //  reset seller info
      dispatch(setSellerInfo({}));
      //  reset selected client
      dispatch(setSelectedClient(null));
      //  reset total cart
      dispatch(setCartTotal(0));
      //  reset cart items
      dispatch(setCart([]));
      //  reset user role
      dispatch(setRole(''));

      setIsLoading(false);
      navigate('/');
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 0.5, md: 1 },
        marginRight: { xs: -1, md: 0 },
      }}
    >
      {hasLogin && SETTINGS.functionalities.shoppingList && (
        <IconButton
          startIcon={<FiBookmark size={24} />}
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.shoppingList}`}
          title='Lista de compras'
        />
      )}

      <IconButton
        // startIcon={<FiShoppingCart size={24} />}
        component={Link}
        to={PAGES_SLUGS.budgetRequest}
        // to={PAGES_SLUGS.cart}
        title='Pedido de orçamento'
        sx={{
          gap: { xs: 0.5, md: 1 },
          marginRight: { md: -1 },
          fontWeight: 500,
        }}
      >
        Pedido de orçamento
        {cart.length !== 0 && (
          <Badge
            badgeContent={cart.reduce(
              (prev, item) => prev + item.quantity.unit + item.quantity.box,
              0
            )}
            sx={{ top: '-10px' }}
          />
        )}
      </IconButton>

      {SETTINGS.functionalities.myAccount &&
        (!hasLogin || isLoading ? (
          <IconButton
            startIcon={<FiUser size={24} />}
            component={Link}
            to='/login'
            title='Login'
          >
            <Typography
              sx={{
                display: { xs: 'none', md: 'block' },
                marginLeft: 1,
              }}
            >
              Login
            </Typography>
          </IconButton>
        ) : (
          <>
            <IconButton
              startIcon={<FiUser size={24} />}
              ref={userRef}
              onClick={() => setIsMenuOpen(true)}
              title='A minha conta'
            >
              <Typography
                sx={{
                  display: { xs: 'none', md: 'block' },
                  fontWeight: 500,
                  marginLeft: 1,
                }}
              >
                {capitalize(userName.split(' ')[0])}
              </Typography>
            </IconButton>
            <UserMenu
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              userRef={userRef}
              accountLinks={ACCOUNT_LINKS?.[role]}
              handleLogout={handleLogout}
            />
          </>
        ))}

      {MOBILE_DEVICES && <MenuMobile />}
    </Box>
  );
};

export default AccountLinks;
