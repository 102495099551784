import { Box, Dialog, Typography, styled } from '@mui/material';
import React from 'react';

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 700,
  padding: 0,
  marginBottom: theme.spacing(1),
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ModalActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const Modal = (props) => {
  const { children, isOpened = false, setIsOpened } = props;
  return (
    <Dialog
      open={isOpened}
      onClose={() => setIsOpened(false)}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          padding: 4,
        },
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default React.memo(Modal);
