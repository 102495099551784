import styled from '@emotion/styled/macro';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { BsWhatsapp } from 'react-icons/bs';
import { CUSTOM_THEME_COLORS, TRANSITION_DURATION } from '../variables';

const WhatsAppBalloon = styled(Box)(({ theme }) => ({
  width: '130px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  position: 'absolute',
  top: '0',
  right: `calc(100% + ${theme.spacing(1)})`,
  fontSize: '0.8rem',
  lineHeight: '1.25',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: CUSTOM_THEME_COLORS.general.lightGray,
  opacity: '0',
  visibility: 'hidden',
  transition: `opacity ${TRANSITION_DURATION}ms ease, visibility ${TRANSITION_DURATION}ms ease`,
}));

const WhatsAppButton = styled('a')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5),
  backgroundColor: '#25d366',
  borderRadius: '100%',
  color: 'white',
  boxShadow: '1px 1px 7px rgba(0,0,0,0.3)',
  transition: `box-shadow ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    boxShadow: '1px 1px 10px rgba(0,0,0,0.4)',
  },
}));

const WhatsAppWidget = () => {
  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: { xs: theme.spacing(1.5), md: theme.spacing(2) },
        right: { xs: theme.spacing(1.5), md: theme.spacing(2) },
        zIndex: 1,
        '&:hover': {
          [WhatsAppBalloon]: {
            opacity: 1,
            visibility: 'visible',
          },
        },
      }}
    >
      <WhatsAppButton
        href='https://api.whatsapp.com/send?phone=351928064010'
        target='_blank'
      >
        <BsWhatsapp size={MOBILE_DEVICES ? 20 : 24} />
      </WhatsAppButton>
      {!MOBILE_DEVICES && (
        <WhatsAppBalloon>
          Precisa de ajuda? <b>Fale connosco</b>
        </WhatsAppBalloon>
      )}
    </Box>
  );
};

export default WhatsAppWidget;
