import { Box, Container, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { getCategories } from '../../../api';
import { setCategories } from '../../../state/categoriesSlice';
import { CategoryButton } from '../../../styles';
import { CUSTOM_THEME_COLORS, NAVBAR_LINKS } from '../../../variables';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';

const NavBar = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
  const [categoriesTree, setCategoriesTree] = useState([]);

  const categoriesMenuRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    getCategories()
      .then((newCategories) => {
        dispatch(setCategories(newCategories));
        setCategoriesTree(newCategories);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box
      ref={navRef}
      sx={{
        position: 'relative',
        backgroundColor:
          CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box component='nav'>
              <Box
                display='flex'
                sx={{
                  width: '100%',
                }}
              >
                {NAVBAR_LINKS.map(({ url, title }) => {
                  return (
                    <Box
                      key={`navbar-menu-${title}`}
                      ref={categoriesMenuRef}
                      onMouseEnter={() => {
                        // setIsCategoriesMenuOpen(true);
                      }}
                      onMouseLeave={() => {
                        // setIsCategoriesMenuOpen(false);
                      }}
                      sx={{
                        '&:first-of-type': {
                          marginLeft: -2.5,
                        },
                        '&:last-of-type': {
                          marginLeft: 'auto',
                          marginRight: -2.5,
                        },
                      }}
                    >
                      <CategoryButton
                        component={NavLink}
                        title={title}
                        to={url}
                        reloadDocument
                        sx={{
                          paddingLeft: 2.5,
                          paddingRight: 2.5,
                          '&:hover': {
                            backgroundColor:
                              CUSTOM_THEME_COLORS[theme.custom.collectionTheme]
                                .lightPrimary,
                          },
                        }}
                      >
                        {title}
                      </CategoryButton>
                      <CategoriesMenu
                        setIsCategoriesMenuOpen={setIsCategoriesMenuOpen}
                        isCategoriesMenuOpen={isCategoriesMenuOpen}
                        anchorEl={navRef.current}
                        categoriesTree={categoriesTree}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NavBar;
