import {
  Box,
  Button,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiCheck, FiEdit2, FiPercent, FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  removeProductFromCart,
  updateCartProducts,
} from '../../../../api/checkout';
import { formatCurrency } from '../../../../constants/utils';
import {
  setCart,
  setCartTotal,
  setDiscount,
} from '../../../../state/cartSlice';
import { BadgeDiscount, IconButton } from '../../../../styles';
import { CUSTOM_THEME_COLORS } from '../../../../variables';
import Quantity from '../../../Quantity/Quantity';
import DiscountSelectorModal from '../DiscountSelectorModal';
import SummaryProductInfo from './SummaryProductInfo';

const SummaryTable = ({ cartItems, cartTotal, selectedClient, role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showDiscountOptions = role === 'seller';
  const showStockAvailability = true;

  const [isApplyDiscountOpen, setIsApplyDiscountOpen] = useState(false);
  const [iconButton, setIconButton] = useState(null);
  const [editDiscountToProduct, setEditDiscountToProduct] = useState();
  const [updatedProductQuantity, setUpdatedProductQuantity] =
    useState(undefined);

  useEffect(() => {
    if (!updatedProductQuantity) return;

    //  update product quantities of product changed
    const updatedProductsQuantities = cartItems.map((product) => ({
      ...product,
      quantity:
        product.reference === updatedProductQuantity.reference
          ? updatedProductQuantity.quantity
          : product.quantity,
    }));

    // updateCartProducts(updatedProductsQuantities, selectedClient.id)
    updateCartProducts(updatedProductsQuantities, selectedClient)
      .then(({ cart_items, total_discount, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity, dispatch]);

  const updateCartItemDiscount = (product, discount, priceWithDiscount) => {
    const productWithDiscount = [
      {
        ...product,
        discountPrice: priceWithDiscount,
        discount: discount,
      },
    ];

    // POST REQUEST » it will trigger a cart object change
    updateCartProducts(productWithDiscount, selectedClient.id)
      .then(({ cart_items, total_discount, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const deleteProductFromCart = (reference) => {
    removeProductFromCart(reference, selectedClient)
      .then(({ cart_items, total_discount, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const deleteAllProductsFromCart = () => {
    const productsQuantitiestoZero = cartItems.map((product) => ({
      ...product,
      quantity: {
        unit: 0,
        box: 0,
      },
    }));
    updateCartProducts(productsQuantitiestoZero, selectedClient)
      .then(({ cart_items, total_discount, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginY: 1 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                Detalhes
              </TableCell>
              <TableCell sx={{ minWidth: { xs: '140px', md: 'initial' } }}>
                Quantidade (caixa)
              </TableCell>
              <TableCell
                sx={{
                  minWidth: { xs: '150px', md: 'initial' },
                  display: 'none',
                }}
              >
                Preço
              </TableCell>
              {showDiscountOptions && (
                <TableCell sx={{ minWidth: { xs: '240px', md: 'initial' } }}>
                  Desconto de Vendedor
                </TableCell>
              )}
              <TableCell
                sx={{
                  minWidth: { xs: '100px', md: 'initial' },
                  display: 'none',
                }}
              >
                Subtotal
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(cartItems) &&
              cartItems.map((product, index) => {
                const {
                  id,
                  title,
                  brand,
                  reference,
                  price,
                  salePrice,
                  onSale,
                  onSaleBox,
                  boxPrice,
                  boxSalePrice,
                  saleAmount,
                  quantity,
                  images,
                  discount,
                  itemsPerBox,
                  stockPerBox,
                } = product;
                const pricePerUnit = quantity.unit //  unit price
                  ? onSale
                    ? salePrice
                    : price
                  : onSaleBox //  box price
                  ? boxSalePrice
                  : boxPrice;
                const productOnSale = onSale || onSaleBox;
                const productPrice = quantity.unit ? price : boxPrice;
                const productSalePrice = quantity.unit
                  ? salePrice
                  : boxSalePrice;
                const hasDiscount = discount > 0;
                const quantityAmount = quantity.unit + quantity.box;
                const subtotal = pricePerUnit * quantityAmount;
                const subtotalWithDiscount = parseFloat(
                  Math.fround(
                    pricePerUnit * quantityAmount -
                      (pricePerUnit * quantityAmount * discount) / 100
                  ).toFixed(2)
                );

                return (
                  <TableRow
                    key={`cart-item-${reference}-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* designation */}
                    <TableCell>
                      <SummaryProductInfo
                        id={id}
                        images={images}
                        title={title}
                        reference={reference}
                        brand={brand}
                        itemsPerBox={itemsPerBox}
                        showStockAvailability={showStockAvailability}
                        stock={stockPerBox}
                      />
                    </TableCell>

                    {/* quantity */}
                    <TableCell>
                      <Quantity
                        product={product}
                        setUpdatedProductQuantity={setUpdatedProductQuantity}
                      />
                    </TableCell>

                    {/* your price */}
                    <TableCell
                      sx={{
                        display: 'none',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: productOnSale && 'line-through',
                          }}
                        >
                          {formatCurrency(productPrice)}
                        </Typography>

                        {productOnSale && (
                          <>
                            <Typography
                              sx={{ fontWeight: 600, color: 'danger.main' }}
                            >
                              {formatCurrency(productSalePrice)}
                            </Typography>
                            <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                          </>
                        )}
                      </Box>
                    </TableCell>

                    {/* seller discount */}
                    {showDiscountOptions && (
                      <TableCell>
                        <IconButton
                          title={
                            hasDiscount ? 'Editar desconto' : 'Aplicar desconto'
                          }
                          sx={{
                            gap: 1,
                            color: hasDiscount && 'danger.main',
                            fontWeight: hasDiscount && 600,
                          }}
                          onMouseEnter={() => {
                            return hasDiscount && setIconButton(index);
                          }}
                          onMouseLeave={() => {
                            return hasDiscount && setIconButton(null);
                          }}
                          onClick={() => {
                            setEditDiscountToProduct(product);
                            setIsApplyDiscountOpen(true);
                          }}
                        >
                          <Icon
                            sx={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {hasDiscount ? (
                              iconButton === index ? (
                                <FiEdit2 size={12} />
                              ) : (
                                <FiCheck size={12} />
                              )
                            ) : (
                              <FiPercent size={12} />
                            )}
                          </Icon>
                          {hasDiscount
                            ? `Desconto ${discount}%`
                            : `Aplicar desconto`}
                        </IconButton>
                      </TableCell>
                    )}

                    {/* subtotal */}
                    <TableCell
                      sx={{
                        display: 'none',
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: hasDiscount && 'line-through',
                          }}
                        >
                          {formatCurrency(subtotal)}
                        </Typography>

                        {hasDiscount && (
                          <Typography
                            sx={{
                              color: 'danger.main',
                              fontWeight: 600,
                            }}
                          >
                            {formatCurrency(subtotalWithDiscount)}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={() => deleteProductFromCart(reference)}
                        title='Remover'
                        sx={{
                          minWidth: 'initial',
                        }}
                      >
                        <FiTrash2 size={16} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        sx={{
          fontSize: '0.9rem',
          textAlign: { md: 'right' },
          marginTop: 1,
        }}
      >
        * O orçamento poderá variar consoante as quantidades selecionadas
      </Typography>

      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          alignItems: { sm: 'center' },
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Box>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            title='Limpar carrinho'
            sx={{
              borderColor: CUSTOM_THEME_COLORS.general.red,
              color: CUSTOM_THEME_COLORS.general.red,
              '&:hover': {
                backgroundColor: CUSTOM_THEME_COLORS.general.red,
              },
            }}
            onClick={() => deleteAllProductsFromCart()}
          >
            Limpar carrinho
          </Button>
        </Box>
        <Box
          sx={{
            display: 'none',
            // display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { sm: 1 },
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
            }}
          >
            Total da encomenda:
          </Typography>
          <Typography
            sx={{
              fontSize: '1.6rem',
              fontWeight: 700,
              color: CUSTOM_THEME_COLORS.general.primary,
            }}
          >
            {formatCurrency(cartTotal)}
          </Typography>
        </Box>
      </Box>

      {isApplyDiscountOpen && (
        <DiscountSelectorModal
          isApplyDiscountOpen={isApplyDiscountOpen}
          setIsApplyDiscountOpen={setIsApplyDiscountOpen}
          updateCartItemDiscount={updateCartItemDiscount}
          product={editDiscountToProduct}
        />
      )}
    </>
  );
};

export default React.memo(SummaryTable);
