import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { ImFacebook } from 'react-icons/im';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { COMPANY_INFO } from '../../variables';

const SocialMedia = () => {
  const { phone } = COMPANY_INFO;
  return (
    <Grid
      item
      xs={10}
      md={3}
      sx={{
        margin: { xs: '0 auto', md: '0' },
      }}
    >
      <Box>
        <Typography
          variant='h4'
          sx={{
            color: 'white',
            textAlign: { xs: 'center', md: 'initial' },
            marginBottom: 2,
          }}
        >
          Apoio ao cliente
        </Typography>

        <Box
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography
            component={'a'}
            href={`tel:${phone.value}`}
            title={phone.label}
            sx={{
              fontSize: '1.4rem',
              color: 'white',
              fontWeight: 600,
              textDecoration: 'none',
            }}
          >
            {phone.label}
          </Typography>
          <Typography
            variant='small'
            sx={{
              color: 'white',
              fontWeight: 300,
            }}
          >
            (Chamada para a rede fixa nacional)
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: 3,
        }}
      >
        <Typography
          variant='h4'
          sx={{
            color: 'white',
            textAlign: { xs: 'center', md: 'initial' },
            marginBottom: 2,
          }}
        >
          Redes Sociais
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'initial' },
          }}
        >
          <IconContext.Provider value={{ style: { color: 'white' } }}>
            {COMPANY_INFO.social.facebook && (
              <Link
                href={COMPANY_INFO.social.facebook}
                target='_blank'
                title='Facebook'
                rel='noreferrer'
                underline='none'
                sx={{
                  display: 'flex',
                  marginLeft: { xs: 0.75, md: 0 },
                  marginRight: 0.75,
                  '&:hover': {
                    opacity: 0.75,
                  },
                }}
              >
                <ImFacebook size={18} />
              </Link>
            )}

            {COMPANY_INFO.social.linkedin && (
              <Link
                href={COMPANY_INFO.social.linkedin}
                target='_blank'
                title='Linkedin'
                rel='noreferrer'
                underline='none'
                sx={{
                  display: 'flex',
                  marginX: 0.75,
                  '&:hover': {
                    opacity: 0.75,
                  },
                }}
              >
                <FaLinkedinIn size={20} />
              </Link>
            )}
            {COMPANY_INFO.social.instagram && (
              <Link
                href={COMPANY_INFO.social.instagram}
                target='_blank'
                title='Instagram'
                rel='noreferrer'
                underline='none'
                sx={{
                  display: 'flex',
                  marginX: 0.75,
                  '&:hover': {
                    opacity: 0.75,
                  },
                }}
              >
                <FaInstagram size={20} />
              </Link>
            )}
          </IconContext.Provider>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' },
          alignItems: 'center',
          gap: 2,
          marginTop: 3,
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/imporvelas-top5-2021.png`}
          alt='Scoring top 2% - melhores PME Portugal'
          width='70'
          height='76'
        />
        <img
          src={`${process.env.PUBLIC_URL}/imporvelas-top5-2022.png`}
          alt='Scoring top 2% - melhores PME Portugal'
          width='86'
          height='76'
        />
      </Box>
    </Grid>
  );
};

export default SocialMedia;
