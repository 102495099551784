import axios from 'axios';
import { API_URL, SETTINGS } from '../variables';
import { getHeaders } from './login';
import { mapFilters, mapProduct } from './mapApiInterface';

//  GET

function getProduct(id) {
  return axios
    .get(`${API_URL}/products/${id}`, getHeaders())
    .then(({ data: { data: product } }) => {
      const updatedProduct = mapProduct(product);
      return updatedProduct;
    });
}

function getProducts({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams,
  }).toString();
  return axios
    .get(`${API_URL}/products?${urlSearchParams}`, getHeaders())
    .then(({ data: { products, productsTotal, filters } }) => {
      const displayedProducts = products.map((product) => mapProduct(product));
      const displayedFilters = mapFilters(filters);
      return { displayedFilters, displayedProducts, productsTotal };
    });
}

function getLatestProducts() {
  return axios
    .get(
      `${API_URL}/products?sortBy=${SETTINGS.defaults.productList.sortBy}&limit=20`,
      getHeaders()
    )
    .then(({ data: { products } }) => {
      return products.map((product) => mapProduct(product));
    });
}

function getProductsOnCampaign() {
  return axios
    .get(`${API_URL}/products?sale=true`, getHeaders())
    .then(({ data: { products } }) => {
      return products.map((product) => mapProduct(product));
    });
}

function getSearchedProducts({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams,
  }).toString();
  return axios
    .get(`${API_URL}/search?${urlSearchParams}`, getHeaders())
    .then(({ data: { products: productsReceived, productsTotal } }) => {
      const products = productsReceived.map((product) => mapProduct(product));
      return { products, productsTotal };
    });
}

// POST

function addProductsToCart(newProducts, session_id) {
  return (
    axios
      .post(
        `${API_URL}/cart/add`,
        { products: newProducts, session_id },
        getHeaders()
      )
      // .post(
      //   `${API_URL}/cart/add`,
      //   { products: newProducts, customerId },
      //   getHeaders()
      // )
      .then(({ data: { cart } }) => ({
        ...cart,
        cart_items: cart.cart_items.map((product) => mapProduct(product)),
      }))
  );
}

function addProductToShoppingList(listId, product_id) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/add`,
      { product_id },
      getHeaders()
    )
    .then(({ data: { lists } }) => lists);
}

export {
  getProduct,
  getProducts,
  getLatestProducts,
  getProductsOnCampaign,
  getSearchedProducts,
  addProductsToCart,
  addProductToShoppingList,
};
