import { Box, Dialog, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addAddress } from '../../../api/user';
import { setAddresses } from '../../../state/userSlice';
import { StyledTextButton } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';

const NewAddressModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Nova morada adicionada com sucesso',
    errorMessage: '',
  });
  const [newDeliveryAddress, setNewDeliveryAddress] = useState({
    street: '',
    zipCode: '',
    city: '',
    country: '',
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setNewDeliveryAddress((values) => ({ ...values, [name]: value }));
  }

  const handleCloseDialog = () => {
    setNewDeliveryAddress({
      street: '',
      zipCode: '',
      city: '',
      country: '',
    });
    setIsOpen(false);
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (!newDeliveryAddress) return;

    addAddress(newDeliveryAddress)
      .then((addresses) => {
        dispatch(setAddresses(addresses));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));

        setNewDeliveryAddress({
          street: '',
          zipCode: '',
          city: '',
          country: '',
        });

        setIsOpen(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status > 401) {
            const errorMessage = errors.reduce(
              (prevValue, { msg }) => prevValue + `${msg}. `,
              ''
            );
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: errorMessage,
            }));
          }
        }
      );
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleCloseDialog();
        }}
        PaperProps={{
          sx: {
            width: { xs: '80%', md: '40%' },
            padding: 4,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            textTransform: 'uppercase',
            marginBottom: 1,
          }}
        >
          Nova Morada
        </Typography>

        <Box
          component='form'
          onSubmit={handleSubmit}
        >
          <Box>
            <TextField
              fullWidth
              multiline
              required
              type='text'
              name='street'
              label='Morada'
              value={newDeliveryAddress.street}
              onChange={handleChange}
              error={!newDeliveryAddress.street}
              helperText={!newDeliveryAddress.street && 'Campo obrigatório.'}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 0, md: 2 },
              }}
            >
              <TextField
                type='text'
                name='zipCode'
                label='Código Postal'
                required
                value={newDeliveryAddress.zipCode}
                onChange={handleChange}
                error={!newDeliveryAddress.zipCode}
                helperText={!newDeliveryAddress.zipCode && 'Campo obrigatório.'}
                sx={{ flex: 1 }}
              />

              <TextField
                type='text'
                name='city'
                label='Cidade'
                required
                value={newDeliveryAddress.city}
                onChange={handleChange}
                error={!newDeliveryAddress.city}
                helperText={!newDeliveryAddress.city && 'Campo obrigatório.'}
                sx={{ flex: 1 }}
              />
            </Box>

            <Box
              sx={{
                width: { xs: '100%', md: `calc(50% - ${theme.spacing(1)})` },
              }}
            >
              <TextField
                fullWidth
                type='text'
                name='country'
                label='País'
                required
                value={newDeliveryAddress.country}
                onChange={handleChange}
                error={!newDeliveryAddress.country}
                helperText={!newDeliveryAddress.country && 'Campo obrigatório.'}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 3,
            }}
          >
            <StyledTextButton
              variant='contained'
              title='Cancelar'
              color='secondary'
              onClick={() => handleCloseDialog()}
            >
              Cancelar
            </StyledTextButton>

            <StyledTextButton
              variant='contained'
              title='Adicionar morada'
              type='submit'
              disabled={
                !newDeliveryAddress.street ||
                !newDeliveryAddress.zipCode ||
                !newDeliveryAddress.city ||
                !newDeliveryAddress.country
                  ? true
                  : false
              }
            >
              Adicionar morada
            </StyledTextButton>
          </Box>
        </Box>
      </Dialog>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default NewAddressModal;
