const API_URL = `${process.env.REACT_APP_API_URL}/store`;
const PLACEHOLDER_IMAGE = `${
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_WEBSITE_URL
    : process.env.PUBLIC_URL
}/default_image.png`;
const NOT_FOUND_ROUTE = 'pagina-nao-encontrada';

//  CUSTOM COLORS
const CUSTOM_THEME_COLORS = {
  general: {
    primary: '#72246C',
    lightPrimary: '#803485',
    gray: '#DDDDDD',
    lightGray: '#f7f7f7',
    darkGray: '#707070',
    red: '#9A031E',
    green: '#AFBE8F',
    yellow: '#FFC01D',
  },
  //  collections colors
  home: {
    primary: '#8AB1B1',
    lightPrimary: '#9DCACA',
  },
  religious: {
    primary: '#A072AF',
    lightPrimary: '#C287D6',
  },
  floral: {
    primary: '#8CAF77',
    lightPrimary: '#A1C989',
  },
  professional: {
    primary: '#A09E9E',
    lightPrimary: '#BCBBBB',
  },
};

//  STYLES DEFAULTS
const TRANSITION_DURATION = 200;

// info
const COMPANY_NAME = 'Imporvelas';
const COMPANY_INFO = {
  name: 'Imporvelas',
  address: {
    street: 'Rua de Santo André, nº75, Parque Industrial de Adaúfe Pavilhão U3',
    zipCode: '4710-596 Braga',
    city: 'Portugal',
  },
  phone: {
    label: '(+351) 253 628 372',
    value: '+351253628372',
  },
  mobile: {
    label: '',
    value: '',
  },
  fax: {
    label: '(+351) 253 626 727',
    value: '+351253626727',
  },
  email: 'geral@imporvelas.com',
  social: {
    linkedin: '',
    facebook: 'https://www.facebook.com/Imporvelas/',
    instagram: 'https://www.instagram.com/imporvelas/',
  },
  mapsLink: 'https://goo.gl/maps/nEfr86jpuBuBL18V8',
};

const SETTINGS = {
  functionalities: {
    myAccount: false,
    shoppingList: false,
    hidePrices: false,
  },
  defaults: {
    productList: {
      sortBy: 'name:asc',
      itemsPerPage: 24,
    },
  },
};

// login
const LOGIN_FORM_TITLE = 'Clientes Profissionais';

const LOGIN_PASS_RECOVERY_TEXT = 'Esqueceu-se da palavra-passe?';

const LOGIN_REGISTER_TEXT = 'Ainda não tem conta de cliente?';

const PASS_RECOVERY_TITLE = 'Recuperar Palavra-Passe';

const PASS_RECOVERY_TEXT =
  'Após recuperar a palavra-passe irá receber uma nova no seu email.';

const USER_REGISTER_TITLE = 'Registo de Novo Utilizador';

const REGISTER_SUCCESS_TEXT =
  'O seu registo foi efetuado com sucesso. Receberá um email com a aprovação da conta.';

const PRODUCT_INFO_TABS = ['Detalhes Técnicos'];
// const PRODUCT_INFO_TABS = ['Detalhes Técnicos', 'Vídeo', 'Downloads'];

// breakpoints
const SMALL_DEVICES_BREAKPOINT = '600px';
const MEDIUM_DEVICES_BREAKPOINT = '900px';
const LARGE_DEVICES_BREAKPOINT = '1200px';
const XLARGE_DEVICES_BREAKPOINT = '1536px';

//  PAGES SLUGS
const PAGES_SLUGS = {
  search: '/pesquisa',
  allProducts: '/produtos',
  budgetRequest: '/pedido-orcamento',
  news: '/novidades',
  promotions: '/promocoes',
  brands: '/marcas',
  contacts: '/contactos',
  myProducts: '/meus-produtos',
  cart: '/carrinho',
  account: '/conta',
  login: '/login',
  productDetail: '/produto',
  recoverPassword: 'recuperar-palavra-passe',
  createUser: 'registo-utilizador',
  //  begin collections pages
  homeColection: '/home',
  religiousColection: '/religiosa',
  floralColection: '/floral',
  professionalColection: '/profissional',
  //  end collections pages
  institutional: {
    aboutUs: 'quem-somos',
    termsAndConditions: 'termos-e-condicoes',
    privacyPolicy: 'politica-de-privacidade',
    alternativeDisputeResolution: 'resolucao-alternativa-de-litigios',
    faqs: 'faqs',
  },
  myAccount: {
    info: `dados-pessoais`,
    accessData: 'dados-acesso',
    receipts: 'conta-corrente',
    shoppingList: 'lista-compras',
    orders: 'encomendas',
    productsHistory: 'visualizacoes',
    users: 'utilizadores',
    addresses: 'moradas',
  },
};

const ACCOUNT_LINKS = {
  customer: [
    {
      title: 'Dados Pessoais',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'Dados de Acesso',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
    },
    {
      title: 'Moradas',
      url: PAGES_SLUGS.myAccount.addresses,
      userMenu: true,
    },
    {
      title: 'Encomendas',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'Conta Corrente',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'Listas de compras',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: false,
    },
    {
      title: 'Visualizações',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: false,
    },
  ],
  seller: [
    {
      title: 'Encomendas',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'Conta Corrente',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'Listas de compras',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: false,
    },
    {
      title: 'Visualizações',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: true,
    },
  ],
};

// navbar
const NAVBAR_LINKS = [
  {
    title: 'Produtos',
    url: PAGES_SLUGS.allProducts,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Religiosa',
    url: PAGES_SLUGS.religiousColection,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Floral',
    url: PAGES_SLUGS.floralColection,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Home',
    url: PAGES_SLUGS.homeColection,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Profissional',
    url: PAGES_SLUGS.professionalColection,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  // {
  //   title: 'Pedido orçamento',
  //   url: PAGES_SLUGS.budgetRequest,
  //   highlighted: false,
  //   categoryButton: false,
  //   menuMobile: true,
  // },
  {
    title: 'Contactos',
    url: PAGES_SLUGS.contacts,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
];

const DELIVERY_METHODS = {
  STORE: 'deliveryAtStore',
  ADDRESS: 'deliveryAtAddress',
};

export {
  API_URL,
  PLACEHOLDER_IMAGE,
  NOT_FOUND_ROUTE,
  COMPANY_NAME,
  COMPANY_INFO,
  LOGIN_FORM_TITLE,
  LOGIN_PASS_RECOVERY_TEXT,
  LOGIN_REGISTER_TEXT,
  PASS_RECOVERY_TITLE,
  PASS_RECOVERY_TEXT,
  USER_REGISTER_TITLE,
  REGISTER_SUCCESS_TEXT,
  SMALL_DEVICES_BREAKPOINT,
  MEDIUM_DEVICES_BREAKPOINT,
  LARGE_DEVICES_BREAKPOINT,
  XLARGE_DEVICES_BREAKPOINT,
  ACCOUNT_LINKS,
  NAVBAR_LINKS,
  PAGES_SLUGS,
  PRODUCT_INFO_TABS,
  TRANSITION_DURATION,
  CUSTOM_THEME_COLORS,
  DELIVERY_METHODS,
  SETTINGS,
};
