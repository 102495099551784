import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { COMPANY_INFO, PAGES_SLUGS } from '../../variables';
import { FooterLink } from '../../styles';

const AboutUs = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h4'
        marginBottom={2}
        sx={{ color: 'white' }}
      >
        {COMPANY_INFO.name}
      </Typography>

      <FooterLink
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={`/${PAGES_SLUGS.institutional.aboutUs}`}
        title='Quem Somos'
      >
        Quem Somos
      </FooterLink>

      <FooterLink
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={PAGES_SLUGS.contacts}
        title='Contactos'
      >
        Contactos
      </FooterLink>
    </Grid>
  );
};

export default AboutUs;
