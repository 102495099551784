import { Box, Typography } from '@mui/material';
import { CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../../variables';
// import ProductStock from '../../ProductStock';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/products';
import {
  setCartTotal,
  setDiscount,
  updateCart,
} from '../../../state/cartSlice.js';
import PopupNotification from '../../PopupNotification/PopupNotification';
import ProductStock from '../../ProductStock';
import ProductCheckout from './ProductCheckout';
import ProductPrices from './ProductPrices';
import ProductVariants from '../ProductVariants/ProductVariants';
import { setSessionId } from '../../../state/userSlice';

const ProductDetails = ({ product, userIsLogged, customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    title,
    description,
    reference,
    stockPerBox,
    itemsPerBox,
    variants,
    variationField,
  } = product;
  const hasVariants = variants.length;
  const variationName = variationField;

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });

  const [productsForCart, setProductsForCart] = useState({
    products: [],
    totalUnits: 0,
    total: 0,
  });

  const addToCart = () => {
    addProductsToCart(productsForCart.products, customerId)
      .then(({ cart_items, total_discount, total, session_id }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
        dispatch(setSessionId(session_id));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: 'Produto adicionado ao pedido',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Typography variant='h1'>{title}</Typography>

        {reference && (
          <Typography
            marginTop={1}
            sx={{ fontSize: '.85em' }}
          >{`Ref: ${reference}`}</Typography>
        )}

        {!hasVariants && (
          <Box sx={{ marginTop: 1 }}>
            <ProductStock stock={stockPerBox} />
          </Box>
        )}

        {description && (
          <Typography
            marginTop={2}
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        {itemsPerBox && (
          <Typography marginTop={2}>
            Embalagem com <strong>{itemsPerBox} unidades</strong>
          </Typography>
        )}

        {hasVariants ? (
          <Box
            sx={{
              marginTop: 3,
            }}
          >
            <ProductVariants
              variants={variants}
              variationName={variationName}
              userIsLogged={userIsLogged}
              productsForCart={productsForCart}
              setProductsForCart={setProductsForCart}
            />
          </Box>
        ) : (
          !userIsLogged && <ProductPrices product={product} />
        )}

        {userIsLogged ? (
          <ProductCheckout
            product={product}
            hasVariants={hasVariants}
            addToCart={addToCart}
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
        ) : (
          <Box sx={{ marginTop: 3 }}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: `Por favor, faça <a href=${PAGES_SLUGS.login} title='Login' style="color:${CUSTOM_THEME_COLORS.general.primary}; font-weight:600">Login</a> para visualizar o preço do produto.`,
              }}
            />
          </Box>
        )}
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ProductDetails;
