import { createSlice } from '@reduxjs/toolkit';

const shoppingListsSlice = createSlice({
  name: 'shoppingLists',

  initialState: {
    shoppingLists: [],
  },

  reducers: {
    setShoppingLists: (state, action) => {
      state.shoppingLists = action.payload;
    },
  },
});

export const { setShoppingLists } = shoppingListsSlice.actions;

export default shoppingListsSlice.reducer;
