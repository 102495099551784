import { Grid, Typography } from '@mui/material';
import { FooterLink } from '../../styles';
import { Link } from 'react-router-dom';
import { PAGES_SLUGS } from '../../variables';

const CustomerSupport = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h4'
        marginBottom={2}
        sx={{ color: 'white' }}
      >
        Informações
      </Typography>

      <FooterLink
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={`/${PAGES_SLUGS.institutional.termsAndConditions}`}
        title='Termos e Condições'
      >
        Termos e Condições
      </FooterLink>

      <FooterLink
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={`/${PAGES_SLUGS.institutional.privacyPolicy}`}
        title='Política de Privacidade'
      >
        Política de Privacidade
      </FooterLink>

      <FooterLink
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={`/${PAGES_SLUGS.institutional.alternativeDisputeResolution}`}
        title='Resolução Alternativa de Litígios'
      >
        Resolução Alternativa de Litígios
      </FooterLink>

      <FooterLink
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={`/${PAGES_SLUGS.institutional.faqs}`}
        title='Faqs'
      >
        Faqs
      </FooterLink>

      <FooterLink
        component='a'
        variant='link'
        marginBottom={0.5}
        href='https://www.livroreclamacoes.pt/Inicio/'
        target='_blank'
        rel='noreferrer'
        title='Livro de Reclamações'
      >
        Livro de Reclamações
      </FooterLink>
    </Grid>
  );
};

export default CustomerSupport;
