import { Box, Typography } from '@mui/material';
import { StyledTextButton } from '../styles';

const NotFoundMessage = () => {
  return (
    <Box
      sx={{
        margin: '20vh auto',
        textAlign: 'center',
      }}
    >
      <Typography
        variant='h1'
        marginBottom={2}
        sx={{
          fontSize: '4em',
        }}
      >
        404
      </Typography>
      <Typography
        variant='h5'
        marginBottom={4}
      >
        Página não encontrada.
      </Typography>

      <StyledTextButton
        variant='contained'
        href='/'
      >
        Continuar a comprar
      </StyledTextButton>
    </Box>
  );
};

export default NotFoundMessage;
