import { Box, Container, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { RiTruckLine } from 'react-icons/ri';
import { FiBox } from 'react-icons/fi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { BsWhatsapp } from 'react-icons/bs';
import { CUSTOM_THEME_COLORS } from '../../variables';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 700,
  [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
    fontSize: '1.15rem',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
    fontSize: '0.9rem',
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  color: CUSTOM_THEME_COLORS.general.primary,
  marginBottom: theme.spacing(2),
}));

const DealsSection = () => {
  return (
    <Box
      sx={{
        marginTop: 5,
        marginBottom: { xs: -4, md: -6 },
        backgroundColor: CUSTOM_THEME_COLORS.general.lightGray,
      }}
    >
      <Container
        sx={{
          paddingY: { xs: 3, md: 6 },
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, md: 4 }}
        >
          <Grid
            xs={12}
            sm={6}
            md={3}
          >
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <IconContainer>
                <RiTruckLine size={32} />
              </IconContainer>
              <Title>Entregas Rápidas</Title>
              <Description>Entrega em 24 - 48 horas</Description>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
          >
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <IconContainer>
                <FiBox size={32} />
              </IconContainer>
              <Title>Portes Gratuitos</Title>
              <Description>
                Em encomendas iguais ou superiores a 500€
              </Description>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
          >
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <IconContainer>
                <HiOutlineUserGroup size={32} />
              </IconContainer>
              <Title>Equipa Experiente</Title>
              <Description>No Mercado há mais de 40 Anos</Description>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
          >
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <IconContainer>
                <BsWhatsapp size={30} />
              </IconContainer>
              <Title>Apoio ao Cliente | Whatsapp</Title>
              <Description>
                <Typography
                  component={'a'}
                  href='https://api.whatsapp.com/send?phone=351928064010'
                  title='Precisa de ajuda? Fale connosco'
                  target='_blank'
                  sx={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                  Precisa de ajuda? Fale connosco
                </Typography>
              </Description>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DealsSection;
