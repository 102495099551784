import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../constants/utils';
import { imageOnError } from '../helpers';
import { ProductCardContext } from '../contexts/ProductCardContext';
import { BadgeDiscount } from '../styles';
import {
  CUSTOM_THEME_COLORS,
  PAGES_SLUGS,
  TRANSITION_DURATION,
} from '../variables';
import ProductBadge from './ProductBadge';
import ProductStock from './ProductStock';

const ProductListCard = ({
  product,
  showPrice = true,
  showStockAvailability = true,
}) => {
  const theme = useTheme();

  const {
    addToCart,
    setProductVariants,
    setIsVariationsModalOpen,
    customerDiscount,
  } = useContext(ProductCardContext);

  const {
    id,
    title,
    images,
    description,
    reference,
    price,
    stockPerBox,
    onSale,
    salePrice,
    saleAmount,
    badge,
    variants,
    itemsPerBox,
  } = product;

  const hasVariants = variants.length;

  return (
    <Card
      raised
      sx={{
        marginY: 3,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        title={title}
        component={Link}
        to={`${PAGES_SLUGS.productDetail}/${id}`}
        sx={{
          position: 'relative',
          maxWidth: '160px',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {badge && <ProductBadge badge={badge} />}

        <CardMedia
          component='img'
          image={images?.[0]}
          onError={imageOnError}
          alt={title}
          sx={{ maxWidth: '100%', objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          // flexDirection: 'column',
          flex: 2,
          padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(
            3
          )} !important`,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              marginBottom: 0.5,
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                color: 'black',
                fontWeight: 700,
                textDecoration: 'none',
                transition: `color ${TRANSITION_DURATION}ms ease`,
                '&:hover': {
                  color:
                    CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary,
                },
              }}
              title={title}
              component={Link}
              to={`${PAGES_SLUGS.productDetail}/${id}`}
            >
              {title}
            </Typography>
            {showStockAvailability && <ProductStock stock={stockPerBox} />}
          </Box>

          {reference && (
            <Typography
              sx={{ color: CUSTOM_THEME_COLORS.general.darkGray }}
              variant='xsmall'
            >{`Ref: ${reference}`}</Typography>
          )}

          {itemsPerBox && (
            <Typography
              variant='xsmall'
              sx={{
                color: CUSTOM_THEME_COLORS.general.darkGray,
              }}
            >
              Embalagem: <strong>{itemsPerBox}</strong> uni.
            </Typography>
          )}

          {description && (
            <Typography
              variant='small'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                lineClamp: 3,
                WebkitBoxOrient: 'vertical',
                marginTop: 1.5,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // justifyContent: 'space-between',
            // marginTop: 'auto',
            // paddingTop: 2.5,
          }}
        >
          {showPrice && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: 700,
                    marginBottom: 1,
                  }}
                >
                  Preço
                </Typography>
                {onSale ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, textDecoration: 'line-through' }}
                    >
                      {formatCurrency(price)}
                    </Typography>
                    <Typography sx={{ fontWeight: 600, color: 'danger.main' }}>
                      {formatCurrency(salePrice)}
                    </Typography>
                  </Box>
                ) : (
                  <Typography>{formatCurrency(price)}</Typography>
                )}
              </Box>

              {(saleAmount || customerDiscount) && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '0.85rem',
                      fontWeight: 700,
                      marginBottom: 1,
                    }}
                  >
                    {saleAmount ? 'Desconto' : 'Desconto cliente'}
                  </Typography>
                  <BadgeDiscount>{`${
                    saleAmount || customerDiscount
                  }%`}</BadgeDiscount>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: 700,
                    marginBottom: 1,
                  }}
                >
                  Preço Líquido
                </Typography>
                <Typography>{formatCurrency(salePrice)}</Typography>
              </Box>
            </Box>
          )}
          <Box>
            <Button
              variant='contained'
              title='Adicionar ao pedido'
              onClick={() =>
                hasVariants
                  ? (setProductVariants(variants),
                    setIsVariationsModalOpen(true))
                  : addToCart(product)
              }
            >
              Adicionar ao pedido
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductListCard;
