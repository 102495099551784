import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { updateUser } from '../../../api/user';
import { compareTwoObjects, objectIsEmpty } from '../../../helpers';
import { setPersonalInfo } from '../../../state/userSlice';
import { MyAccountTitle } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';
import CompanyInfo from './CompanyInfo';
import PersonalInfo from './PersonalInfo';
import SellerInfo from './SellerInfo';

const AccountInfo = () => {
  const dispatch = useDispatch();
  const [SMALL_DEVICES] = useOutletContext();
  const personalInfo = useSelector((state) => state.user.personalInfo);
  const companyInfo = useSelector((state) => state.user.companyInfo);
  const sellerInfo = useSelector((state) => state.user.sellerInfo);

  const { name, phone, email } = personalInfo;
  const [userData, setUserData] = useState({ name, phone, email });
  const [userDataChanged, setUserDataChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Alterações guardadas com sucesso',
    errorMessage: '',
  });

  useEffect(() => {
    if (!personalInfo && !userData) return;

    const userDataDifferences = compareTwoObjects(personalInfo, userData);
    const userDataHasDifferences = !objectIsEmpty(userDataDifferences);

    if (userDataHasDifferences) {
      if (!userDataChanged) setUserDataChanged(true);
    } else {
      if (userDataChanged) setUserDataChanged(false);
    }
  }, [userData, personalInfo, userDataChanged]);

  function handleChange(e) {
    const { name, value } = e.target;
    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!userData) return;

    setIsLoading(true);

    updateUser(userData)
      .then(({ user: { personalInfo } }) => {
        dispatch(setPersonalInfo(personalInfo));
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            status,
            data: { errors },
          },
        }) => {
          if (status === 422) {
            setUserData((values) => ({ ...values, [errors?.[0]?.param]: '' }));
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: errors?.[0]?.msg,
            }));
          }
          setIsLoading(false);
        }
      );
  }

  if (!personalInfo || !companyInfo || !sellerInfo || !userData)
    return <Typography>Não há dados para mostrar.</Typography>;

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box marginBottom={1.5}>
          <MyAccountTitle>Informações da Empresa</MyAccountTitle>
          <Typography variant='small'>
            Consulte os dados da sua empresa e edite a sua informação de
            contacto.
          </Typography>
        </Box>

        <CompanyInfo data={companyInfo} />

        <Box
          sx={{
            marginY: 4,
            display: 'flex',
            flexDirection: SMALL_DEVICES && 'column',
            gap: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <MyAccountTitle>Informações de contacto</MyAccountTitle>
            <PersonalInfo
              handleChange={handleChange}
              data={userData}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <MyAccountTitle>Informações do vendedor</MyAccountTitle>
            <SellerInfo data={sellerInfo} />
          </Box>
        </Box>

        <LoadingButton
          variant='contained'
          type='submit'
          title='Guardar alterações'
          sx={{ marginRight: 'auto' }}
          disabled={
            !userData.name ||
            !userData.phone ||
            !userData.email ||
            !userDataChanged
              ? true
              : false
          }
          loading={isLoading}
        >
          Guardar alterações
        </LoadingButton>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default AccountInfo;
