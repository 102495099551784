import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPreviousOrders } from '../../../api/user';
import { setPreviousOrders } from '../../../state/userSlice';
import Loading from '../../Loading';
import OrdersTable from './OrdersTable';
import AccountFilters from '../AccountFilters';
import { MyAccountTitle } from '../../../styles';

const AccountPurchases = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orders = useSelector((state) => state.user.orders);
  const user = useSelector((state) => state.user);

  const [entities, setEntities] = useState({ orders: [] });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedYear, setSelectedYear] = useState('all');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const customerId = user?.selectedClient?.id || user?.id;

  useEffect(() => {
    setIsLoading(true);

    getPreviousOrders(customerId)
      .then((orders) => {
        dispatch(setPreviousOrders(orders.reverse()));
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId]);

  useEffect(() => {
    const entities = { orders };

    setEntities({ ...entities });

    setSelectedStatus('all');

    setSelectedYear('all');
  }, [orders]);

  const detailsMode = entities.orders;

  const shownEntities = [...detailsMode];

  const filteredByStatus = shownEntities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    reference?.includes(search)
  );

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box marginBottom={2}>
        <MyAccountTitle>Encomendas</MyAccountTitle>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <AccountFilters
            type={detailsMode}
            selectedStatus={selectedStatus}
            selectedYear={selectedYear}
            setSelectedStatus={setSelectedStatus}
            setSelectedYear={setSelectedYear}
            search={search}
            setSearch={setSearch}
          />

          {filteredByEverything.length !== 0 && (
            <>
              <OrdersTable shownOrders={filteredByEverything} />
            </>
          )}

          {filteredByEverything.length === 0 && (
            <Typography sx={{ marginY: 2 }}>
              Não há registos para mostrar com estes parâmetros.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountPurchases;
