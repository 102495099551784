import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',

  initialState: {
    personalInfo: { name: '' },
    companyInfo: {},
    role: '',
    sellerInfo: {},
    selectedClient: {},
    productsHistory: [],
    addresses: [],
    orders: [],
    receipts: [],
    id: null,
    priceIsVisible: false,
    sessionId: '',
    collectionTheme: 'general',
  },

  reducers: {
    setUserId: (state, action) => {
      state.id = action.payload;
    },

    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },

    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },

    setRole: (state, action) => {
      state.role = action.payload;
    },

    setSellerInfo: (state, action) => {
      state.sellerInfo = action.payload;
    },

    setSelectedClient: (state, { payload }) => {
      if (payload !== null) {
        const selectedClient = state.sellerInfo.clients.find((client) => {
          return client.companyInfo.name === payload;
        });

        state.selectedClient = selectedClient;
      } else {
        state.selectedClient = {};
      }
    },

    updateProductsHistory: (state, action) => {
      state.productsHistory = action.payload;
    },

    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },

    setPreviousOrders: (state, action) => {
      state.orders = action.payload;
    },

    setReceipts: (state, action) => {
      state.receipts = action.payload;
    },

    setPriceVisibility: (state, action) => {
      state.priceIsVisible = action.payload;
    },

    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },

    setCollectionTheme: (state, action) => {
      state.collectionTheme = action.payload;
    },
  },
});

export const {
  setUserId,
  setPersonalInfo,
  setCompanyInfo,
  setRole,
  setSellerInfo,
  setSelectedClient,
  updateProductsHistory,
  setAddresses,
  setPreviousOrders,
  setReceipts,
  setPriceVisibility,
  setSessionId,
  setCollectionTheme,
} = userSlice.actions;

export default userSlice.reducer;
