import React from 'react';
import ProductFilter from '../ProductFilter';
import { useContext } from 'react';
import { ProductsListContext } from '../../contexts/ProductsListContext';

const ProductsListFilters = () => {
  const { filters, setPagination } = useContext(ProductsListContext);
  return filters.list.map((filter, index) => (
    <ProductFilter
      key={`products-filter-${filter.id}`}
      index={index}
      filter={filter}
      activeFilters={filters.active}
      setPage={setPagination}
    />
  ));
};

export default ProductsListFilters;
