import {
  // IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import { BiPrinter } from 'react-icons/bi';
import { formatCurrency } from '../../../constants/utils';
// import generatePDF from '../../../helpers/generatePDF';

const ReceiptsTable = ({ receipts }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ marginY: 2 }}
    >
      <Table
        size='small'
        id='receipts-table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Número</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Data de vencimento</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Por pagar</TableCell>
            {/* <TableCell> </TableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {receipts.map(({ reference, date, dueDate, status, value: total, balance: pending }) => (
            <TableRow
              key={`${reference}-${date}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{reference}</TableCell>

              <TableCell sx={{ whiteSpace: 'nowrap' }}>{date}</TableCell>

              <TableCell>{status}</TableCell>

              <TableCell sx={{ whiteSpace: 'nowrap' }}>{dueDate}</TableCell>

              <TableCell>{formatCurrency(total)}</TableCell>

              <TableCell>{formatCurrency(pending)}</TableCell>

              {/* <TableCell>
                  <IconButton
                    size='small'
                    title='Exportar em PDF'
                    onClick={() => generatePDF(reference, formatCurrency(total), date, items)}
                  >
                    <BiPrinter />
                  </IconButton>
                </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptsTable;
