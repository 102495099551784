import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/products';
import PopupNotification from '../components/PopupNotification/PopupNotification';
import ProductVariantsModal from '../components/ProductVariantsModal';
import { setCartTotal, setDiscount, updateCart } from '../state/cartSlice';
import { setSessionId } from '../state/userSlice';

export const ProductCardContext = createContext();

export const ProductCardProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { sessionId, customer_discount: customerDiscount } = user;
  // const { selectedClient, customer_discount: customerDiscount } = user;

  const [isVariationsModalOpen, setIsVariationsModalOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });
  const [productVariants, setProductVariants] = useState([]);
  const [productsForCart, setProductsForCart] = useState({
    products: [],
    totalUnits: 0,
    total: 0,
  });

  const addToCart = (product) => {
    const productsToCart = product
      ? [
          {
            // SIMPLE PRODUCT
            ...product,
            quantity: {
              ...product.quantity,
              box: 1,
            },
          },
        ]
      : productsForCart.products; // VARIANTS PRODUCT

    addProductsToCart(productsToCart, sessionId)
      .then(({ cart_items, total_discount, total, session_id }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
        dispatch(setSessionId(session_id));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: 'Produto adicionado ao pedido',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login', msg);
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <ProductCardContext.Provider
      value={{
        setIsVariationsModalOpen,
        productVariants,
        setProductVariants,
        customerDiscount,
        addToCart,
      }}
    >
      {children}
      <ProductVariantsModal
        isVariationsModalOpen={isVariationsModalOpen}
        setIsVariationsModalOpen={setIsVariationsModalOpen}
        productsForCart={productsForCart}
        setProductsForCart={setProductsForCart}
      />
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </ProductCardContext.Provider>
  );
};
