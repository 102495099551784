import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { COMPANY_NAME } from '../variables';
import GeneralLogo from '../assets/company-logo.svg';
import HomeLogo from '../assets/home_company-logo.svg';
import ReligiousLogo from '../assets/religious_company-logo.svg';
import FloralLogo from '../assets/floral_company-logo.svg';
import ProfessionalLogo from '../assets/professional_company-logo.svg';
import { useSelector } from 'react-redux';

const CompanyLogo = ({ link = true, width = { xs: '120px', md: '180px' } }) => {
  const collectionTheme = useSelector((state) => state.user.collectionTheme);

  let Logo = GeneralLogo;

  switch (collectionTheme) {
    case 'home':
      Logo = HomeLogo;
      break;
    case 'religious':
      Logo = ReligiousLogo;
      break;
    case 'floral':
      Logo = FloralLogo;
      break;
    case 'professional':
      Logo = ProfessionalLogo;
      break;
    default:
      break;
  }

  return (
    <Box
      component={link ? Link : undefined}
      to={link ? '/' : undefined}
      title={link ? COMPANY_NAME : undefined}
      sx={{ display: 'flex', width: width }}
    >
      <img
        src={Logo}
        alt={COMPANY_NAME}
        width='100%'
      />
    </Box>
  );
};

export default CompanyLogo;
