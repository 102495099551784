import {
  Accordion,
  Box,
  Button,
  outlinedInputClasses,
  Select,
  Typography,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CUSTOM_THEME_COLORS, TRANSITION_DURATION } from './variables';

export const createThemeMethod = (collectionTheme) => {
  let theme = createTheme();
  return createTheme(
    {
      shadows: ['none', ...Array(25).fill('none')],

      shape: {
        borderRadius: 10,
      },

      palette: {
        primary: {
          main: '#000',
          dark: CUSTOM_THEME_COLORS[collectionTheme].primary,
        },
        secondary: {
          main: '#fff',
          dark: '#fff',
        },
        text: {
          primary: '#000',
          // disabled: '#ff0000',
        },
        error: {
          main: CUSTOM_THEME_COLORS.general.red,
        },
        danger: {
          main: CUSTOM_THEME_COLORS.general.red,
        },
        action: {
          // disabled: '#ff0000',
          // activatedOpacity: 1,
          // active: string;
          // hover: string;
          // hoverOpacity: number;
          // selected: string;
          // selectedOpacity: number;
          // disabled: string;
          // disabledOpacity: number;
          // disabledBackground: string;
          // focus: string;
          // focusOpacity: number;
          // activatedOpacity: number;
        },
      },

      typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        h1: {
          fontSize: '1.6rem',
          fontWeight: 700,
        },
        h2: {
          fontSize: '1.3rem',
          fontWeight: 700,
        },
        h3: {
          fontSize: '1.2rem',
          fontWeight: 700,
        },
        h4: {
          fontSize: '1rem',
          fontWeight: 600,
        },
        h5: {
          fontSize: '1rem',
          fontWeight: 600,
        },
        overline: {
          lineHeight: 1,
          textTransform: 'none',
        },
        small: {
          fontSize: '0.9rem',
          lineHeight: 1.5,
        },
        xsmall: {
          fontSize: '0.85rem',
          lineHeight: 1.5,
        },
        link: {
          color: theme.palette.text.primary,
          textDecoration: 'none',
          transition: `color ${TRANSITION_DURATION}ms ease`,
          '&:hover': {
            color: CUSTOM_THEME_COLORS[collectionTheme].primary,
          },
        },
      },

      components: {
        MuiCssBaseline: {
          styleOverrides: ({ breakpoints }) => ({
            html: {
              fontSize: '14px',
              [breakpoints.down(breakpoints.values.md)]: {
                fontSize: '13px',
              },
            },
          }),
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              [theme.breakpoints.down(theme.breakpoints.values.md)]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              },
            },
          },
        },
        MuiGrid2: {
          defaultProps: {
            spacing: { xs: 1, md: 2 },
          },
        },
        MuiTypography: {
          styleOverrides: {
            caption: {
              color: theme.palette.text.primary,
            },
          },
          defaultProps: {
            variantMapping: {
              small: 'p',
              xsmall: 'p',
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true, // No more ripple, on the whole application 💣!
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: '0.85rem',
              fontWeight: 400,
              textTransform: 'none',
              padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
            },
            containedPrimary: {
              backgroundColor: CUSTOM_THEME_COLORS[collectionTheme].primary,
              '&:hover': {
                backgroundColor:
                  CUSTOM_THEME_COLORS[collectionTheme].lightPrimary,
              },
            },
            containedSecondary: {
              border: `1px solid ${CUSTOM_THEME_COLORS[collectionTheme].primary}`,
              color: CUSTOM_THEME_COLORS[collectionTheme].primary,
              '&:hover': {
                backgroundColor: CUSTOM_THEME_COLORS[collectionTheme].primary,
                color: 'white',
              },
            },
            sizeSmall: {
              padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
            },
          },
        },
        MuiCircularProgress: {
          styleOverrides: {
            colorPrimary: {
              color: CUSTOM_THEME_COLORS[collectionTheme].primary,
            },
          },
        },
        MuiBadge: {
          styleOverrides: {
            standard: {
              backgroundColor: CUSTOM_THEME_COLORS[collectionTheme].primary,
              color: '#fff',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: CUSTOM_THEME_COLORS.general.darkGray,
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginLeft: 0,
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(3),
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              color: '#97595d',
              '&.Mui-checked': {
                color: CUSTOM_THEME_COLORS[collectionTheme].primary,
              },
            },
            track: {
              backgroundColor: CUSTOM_THEME_COLORS[collectionTheme].primary,
              '.Mui-checked.Mui-checked + &': {
                backgroundColor: CUSTOM_THEME_COLORS[collectionTheme].primary,
              },
            },
          },
        },
        MuiStep: {
          styleOverrides: {
            root: {
              '&.Mui-completed': {
                '.MuiStepLabel-label, .MuiStepIcon-root': {
                  color: CUSTOM_THEME_COLORS[collectionTheme].primary,
                },
              },
              '.Mui-active': {
                '&.MuiStepLabel-label, &.MuiStepIcon-root': {
                  fontWeight: 600,
                  color: CUSTOM_THEME_COLORS[collectionTheme].primary,
                },
              },
              '.MuiStepLabel-root': {
                '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                  marginTop: theme.spacing(1.5),
                },
              },
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              '.MuiTableCell-head': {
                fontWeight: 600,
                backgroundColor: CUSTOM_THEME_COLORS.general.gray,
                textAlign: 'center',
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
              },
              '.MuiTableCell-body': {
                textAlign: 'center',
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              border: `1px solid ${CUSTOM_THEME_COLORS.general.gray}`,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              border: 0,
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              '.MuiFormLabel-root, .MuiOutlinedInput-root': {
                fontSize: '0.95rem',
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            listbox: {
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            },
            option: {
              whiteSpace: 'initial',
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              border: 0,
              borderRadius: '0 !important',
              borderBottom: '1px solid black',
              backgroundColor: 'transparent',
              '&::before': {
                display: 'none',
              },
              '&.Mui-expanded': {
                margin: 0,
              },
              '& .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: '48px',
              },
              '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: 0,
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              display: 'none',
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },

      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1400,
          xl: 1536,
        },
      },
    },
    {
      custom: {
        collectionTheme: collectionTheme,
      },
    }
  );
};

export const MainContainer = styled(Box, { label: 'MainContainer' })(
  ({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    // overflow: 'hidden',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  })
);

export const BadgeDiscount = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(0.2)} ${theme.spacing(1.5)}`,
  fontSize: '0.85rem',
  color: 'white',
}));

export const StyledOrderSummaryBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTextButton = styled(Button)(() => ({
  textTransform: 'none',
}));

export const IconButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: 'initial',
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));

export const FooterLink = styled(Typography)(() => ({
  display: 'block',
  color: 'white',
  textDecoration: 'none',
  fontWeight: 300,
  '&:hover': {
    color: 'white',
    textDecoration: 'underline',
  },
}));

export const StyledTableSelect = styled(Select)(() => ({
  fontSize: '0.9rem',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
}));

export const StyledOrderSummaryText = styled(Typography)(() => ({
  fontSize: '0.85rem',
  fontWeight: 700,
}));

//  PRODUCT LIST
export const ProductsFilters = styled(Accordion)(({ theme }) => ({
  [`& .MuiAccordionSummary-root.Mui-expanded`]: {
    minHeight: '48px',
  },
  [`& .MuiAccordionSummary-content.Mui-expanded`]: {
    margin: 0,
  },
  [`& .MuiAccordionDetails-root`]: {
    paddingTop: theme.spacing(2),
    [`.MuiFormGroup-root`]: {
      gap: theme.spacing(0.5),
    },
  },
}));

//  MAIN MENU
export const CategoryButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  fontSize: '1rem',
  color: 'white',
  borderRadius: 0,
  '&.active::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '3px',
    backgroundColor: 'white',
  },
  [theme.breakpoints.down(theme.breakpoints.values.md)]: {
    fontSize: '0.85rem',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export const CategoriesButton = styled(Box)(({ active }) => ({
  textDecoration: 'none',
  color: 'black',
  fontWeight: 700,
  fontSize: '0.85rem',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${active ? 'black' : 'transparent'}`,
  [`&:hover`]: {
    borderColor: 'black',
  },
}));

export const SubCategoriesButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  color: 'black',
  fontSize: '0.85rem',
  [`&:hover`]: {
    color: CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary,
  },
}));

//  MY ACCOUNT
export const MyAccountTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}));

export const MyAccountSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));
