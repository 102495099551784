import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { getProductsOnCampaign } from '../api/products';
import ProductImage from '../assets/sample-product.jpg';
import Loading from '../components/Loading';
import Modal, { ModalContent, ModalTitle } from '../components/Modal';
import { imageOnError } from '../helpers';
import { MainContainer } from '../styles';
import { CUSTOM_THEME_COLORS } from '../variables';

function Campaigns() {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [isLoading, _setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   setIsLoading(true);

  //   getProductsOnCampaign()
  //     .then((data) => {
  //       setProducts(data);

  //       setIsLoading(false);
  //     })
  //     .catch(({ response: { status } }) => {
  //       if (status === 401) navigate('/login');
  //     })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <MainContainer>
        <Container>
          <Typography
            variant='h1'
            sx={{ textAlign: 'center', marginBottom: 4 }}
          >
            Promoções
          </Typography>

          <Grid
            container
            spacing={{ xs: 1, md: 3 }}
          >
            <Grid
              key={`promotions-card-33`}
              xs={12}
              sm={6}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: { xs: 2, md: 4 },
                  padding: { xs: 2, md: 2.5 },
                  backgroundColor: CUSTOM_THEME_COLORS.general.lightGray,
                  borderRadius: theme.shape.borderRadius / 10,
                }}
              >
                <Box
                  component='img'
                  src={ProductImage}
                  alt='Imagem destacada'
                  onError={(e) => {
                    imageOnError(e);
                  }}
                  sx={{
                    alignSelf: 'flex-start',
                    aspectRatio: '1',
                    objectFit: 'contain',
                    maxWidth: { xs: '125px', lg: '175px' },
                    border: `1px solid ${CUSTOM_THEME_COLORS.general.gray}`,
                    backgroundColor: 'white',
                    borderRadius: theme.shape.borderRadius / 10,
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.15rem',
                      fontWeight: 600,
                    }}
                  >
                    Promoção Bolo do Caco
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.9rem',
                      marginTop: 1,
                    }}
                  >
                    Na compra de duas unidades de Hambúrguer Bovino, OFERTA de
                    uma caixa de Bolo do Caco
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.9rem',
                      color: CUSTOM_THEME_COLORS.general.primary,
                      marginTop: 1,
                    }}
                  >
                    Promoção válida de 04/04/2023 a 30/04/2023
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      marginTop: 'auto',
                      paddingTop: 2,
                    }}
                  >
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      title='Mais informações'
                      sx={{
                        flexBasis: ' 100%',
                      }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Mais informações
                    </Button>
                    <Button
                      variant='contained'
                      size='small'
                      title='Adicionar ao pedido'
                      sx={{
                        flexBasis: ' 100%',
                      }}
                    >
                      Adicionar ao pedido
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid>
                <Typography>
                  De momento, não existem campanhas disponíveis.
                </Typography>
              </Grid> */}
          </Grid>
        </Container>
      </MainContainer>
      <Modal
        isOpened={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <ModalTitle>Promoção Bolo do Caco</ModalTitle>
        <ModalContent>
          <Typography
            sx={{
              fontSize: '0.9rem',
            }}
          >
            Na compra de duas unidades de Hambúrguer Bovino, OFERTA de uma caixa
            de Bolo do Caco
          </Typography>
        </ModalContent>
        {/* <ModalActions
          sx={{
            justifyContent: 'space-between',
            marginTop: 0,
          }}
        >
          <ProductVariantsDetails
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-end',
            }}
          >
            <Button
              title='Cancelar'
              variant='contained'
              color='secondary'
              onClick={() => setIsVariationsModalOpen(false)}
            >
              Cancelar
            </Button>

            <Button
              title='Adicionar ao pedido'
              variant='contained'
              onClick={() => addToCart()}
            >
              Adicionar ao pedido
            </Button>
          </Box>
        </ModalActions> */}
      </Modal>
    </>
  );
}

export default Campaigns;
