import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getReceipts } from '../../../api/user';
import { setReceipts } from '../../../state/userSlice';
import Loading from '../../Loading';
import AccountFilters from '../AccountFilters';
import ReceiptsTable from './ReceiptsTable';
import { MyAccountTitle } from '../../../styles';
// import ExportToExcelButton from './ExportToExcelButton';

const AccountReceipts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const receipts = useSelector((state) => state.user.receipts);
  const user = useSelector((state) => state.user);

  const [entities, setEntities] = useState({ receipts: [] });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedYear, setSelectedYear] = useState('all');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const customerId = user?.selectedClient?.id || user?.id;

  useEffect(() => {
    setIsLoading(true);

    getReceipts(customerId)
      .then((receipts) => {
        dispatch(setReceipts(receipts.reverse()));
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId]);

  useEffect(() => {
    const entities = { receipts };
    setEntities({ ...entities });
  }, [receipts]);

  const detailsMode = entities.receipts;

  const shownEntities = [...detailsMode];

  const filteredByStatus = shownEntities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    reference?.includes(search)
  );

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box marginBottom={2}>
        <MyAccountTitle>Conta-corrente</MyAccountTitle>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <AccountFilters
            type={detailsMode}
            selectedStatus={selectedStatus}
            selectedYear={selectedYear}
            setSelectedStatus={setSelectedStatus}
            setSelectedYear={setSelectedYear}
            search={search}
            setSearch={setSearch}
          />

          {filteredByEverything.length !== 0 && (
            <>
              <ReceiptsTable receipts={filteredByEverything} />
              {/* <ExportToExcelButton isOrders={isOrders} /> */}
            </>
          )}

          {filteredByEverything.length === 0 && (
            <Typography sx={{ marginY: 2 }}>
              Não há registos para mostrar com estes parâmetros.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountReceipts;
