import capitalize from 'lodash/capitalize';
import { PLACEHOLDER_IMAGE } from '../variables';

function mapProduct(product) {
  // const randomPrice = generateRandomPrice();

  //  update interface of 'related'
  const hasRelated = Boolean(
    product?.related !== undefined && product?.related.length !== 0
  );

  //  update interface of 'variants'
  const hasVariants = Boolean(
    product?.variants && product?.variants.length !== 0
  );

  return {
    ...product,
    id: product?._id || product?.id,
    title: product?.title,
    brand: product?.brand,
    description: product?.description,
    reference: product?.reference,
    images: product?.images?.length ? product?.images : [PLACEHOLDER_IMAGE],
    discount: product?.discount,
    discountPrice: product?.discountPrice,
    onSale: product.onSale,
    onSaleBox: product.onSaleBox,
    saleAmount: product.saleAmount,
    price: product.price,
    salePrice: product.salePrice,
    boxPrice: product.boxPrice,
    boxSalePrice: product.boxSalePrice,
    itemsPerBox: product.itemsPerBox,
    stock: product?.stock,
    stockPerBox: product?.stockPerBox,
    quantity: {
      unit: product?.quantity?.unit || 0,
      box: product?.quantity?.box || 0,
    },
    video: product?.video,
    datasheet: product?.datasheet,
    params: product?.params,
    date: product?.erp_update_date || product?.date,
    badge: product?.badge,
    isVariant: product?.isVariant || false,
    related: hasRelated
      ? product?.related.map((related) => mapProduct(related))
      : [],
    variationField: product?.variationField,
    variants: hasVariants
      ? product?.variants?.map((variant) =>
          mapProduct({
            ...variant,
            id: product?._id || product?.id,
            variationField: product?.variationField,
          })
        )
      : [],
  };
}

function mapCategory(collection) {
  return {
    ...collection,
    id: collection._id,
    text: capitalize(collection.title),
    url: collection.url,
    subcategories: collection.subcategories.map((category) => ({
      ...category,
      id: category._id,
      text: capitalize(category.title.toString()),
      url: `/${collection.url}/${category.url}`,
      subcategory: category.productTypes.map((productTypes) => ({
        ...productTypes,
        id: productTypes._id,
        text: capitalize(productTypes.title.toString()),
        url: `/${collection.url}/${category.url}/${productTypes.url}`,
      })),
    })),
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map(([key, value]) => {
    const { title, values } = value;
    return {
      id: key,
      title: title,
      options: values,
    };
  });
}

export { mapCategory, mapFilters, mapProduct };
