import { Box, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { ProductCardProvider } from '../../contexts/ProductCardContext';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import Loading from '../Loading';
import ProductCard from '../ProductCard';
import ProductListCard from '../ProductListCard';
import { useSearchParams } from 'react-router-dom';
import { TRANSITION_DURATION } from '../../variables';

const ProductsList = () => {
  const { products, pagination, layout, isLoading } =
    useContext(ProductsListContext);

  const [cookies] = useCookies(['userToken']);
  const priceIsVisible = useSelector((state) => state.user.priceIsVisible);
  const showPrice = !!cookies.userToken && priceIsVisible;

  const [searchParams] = useSearchParams();
  const searchPage = searchParams.get('query');

  if (products.total === null && isLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        opacity: products.total !== null && isLoading ? '0.5' : null,
        transition: `opacity ${TRANSITION_DURATION}ms ease`,
      }}
    >
      {products.total !== null &&
        (!!products.total ? (
          <>
            <ProductCardProvider>
              {layout === 'grid' ? (
                <Grid
                  container
                  spacing={3}
                  sx={{
                    padding: 0,
                  }}
                >
                  {products.list.map((product, index) => (
                    <Grid
                      key={`product-card-${product.reference}-${index}`}
                      xs={6}
                      sm={searchPage ? 3 : 4}
                      lg={searchPage ? 2 : 3}
                    >
                      <ProductCard
                        showPrice={showPrice}
                        product={product}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                products.list.map((product) => (
                  <ProductListCard
                    key={`product-listcard-${product.reference}`}
                    showPrice={showPrice}
                    product={product}
                  />
                ))
              )}
            </ProductCardProvider>

            {pagination.total > 1 && (
              <Pagination
                count={pagination.total}
                page={pagination.current}
                onChange={pagination.onChange}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: { xs: 3, md: 5 },
                }}
              />
            )}
          </>
        ) : (
          <Typography>Não foram encontrados produtos.</Typography>
        ))}
    </Box>
  );
};

export default ProductsList;
