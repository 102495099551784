import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CUSTOM_THEME_COLORS } from '../variables';

const ProductLabel = ({ type }) => {
  const theme = useTheme();
  let label = '';

  switch (type) {
    case 'stock':
      label = 'Esgotado';
      break;
    case 'new':
      label = 'Novidade';
      break;
    case 'promotion':
      label = 'Promoção';
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        paddingX: 1.25,
        paddingY: 0.5,
        backgroundColor:
          type === 'stock'
            ? CUSTOM_THEME_COLORS.general.gray
            : type === 'new'
            ? CUSTOM_THEME_COLORS.general.green
            : CUSTOM_THEME_COLORS.general.red,
        borderRadius: theme.shape.borderRadius / 10,
      }}
    >
      <Typography
        sx={{
          fontSize: '0.75rem',
          color: type === 'stock' ? 'black' : 'white',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ProductLabel;
