import { Box, Container, Typography } from '@mui/material';
import React from 'react';
// import BudgetRequestForm from '../components/ContactForm/ContactForm';
import Grid from '@mui/material/Unstable_Grid2';
import StoreLocations from '../components/StoreLocations';
import { MainContainer } from '../styles';

const ContactsPage = () => {
  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography variant='h1'>Contactos</Typography>
              <Typography marginTop={2}>
                Fale connosco temos uma equipa ao seu dispor.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box marginTop={{ xs: 3, md: 6 }}>
              <StoreLocations />
            </Box>
            {/* <BudgetRequestForm /> */}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ContactsPage;
