import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  COMPANY_NAME,
  CUSTOM_THEME_COLORS,
  TRANSITION_DURATION,
} from '../../variables';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import CustomerSupport from './CustomerSupport';
import FooterMobileAccordion from './FooterMobileAccordion';
import SocialMedia from './SocialMedia';

const Footer = () => {
  const theme = useTheme();
  const DESKTOP_DEVICES = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Box
        sx={{
          position: { xs: 'relative', md: 'initial' },
          zIndex: { xs: 2, md: 'initial' },
          backgroundColor:
            CUSTOM_THEME_COLORS[theme.custom.collectionTheme].primary,
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 4, md: 6 },
            paddingTop: 5,
            paddingBottom: { xs: 2, md: 5 },
          }}
        >
          <Container maxWidth='lg'>
            <Grid
              container
              spacing={{ xs: 0, md: 5 }}
            >
              {DESKTOP_DEVICES ? (
                <>
                  <SocialMedia />
                  <AboutUs />
                  <CustomerSupport />
                  <ContactUs />
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    margin: '0 auto',
                  }}
                >
                  <SocialMedia />
                  <FooterMobileAccordion />
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>

        <Box>
          <Container maxWidth='lg'>
            <Grid container>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  borderTop: { md: '1px solid rgba(255, 255, 255, 0.40)' },
                }}
              >
                <Typography
                  sx={{
                    paddingY: 2,
                    color: 'white',
                    fontWeight: 300,
                  }}
                >
                  {COMPANY_NAME} © 2023&nbsp;.&nbsp;
                  <Typography
                    component='a'
                    href='https://www.bdcadigital.com/plataforma-de-ecommerce-b2b'
                    title='Powered by BDCA Digital'
                    target='_blank'
                    sx={{
                      fontSize: 'inherit',
                      color: 'white',
                      fontWeight: 600,
                      textDecoration: 'none',
                      transition: `opacity ${TRANSITION_DURATION}ms ease`,
                      '&:hover': { opacity: '.75' },
                    }}
                  >
                    B2B Platform by BDCA Digital
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
