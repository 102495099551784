import { Typography } from '@mui/material';
import React from 'react';

const ProductBadge = ({ badge }) => {
  const badgeLabel = badge === 1 ? 'Novidade' : badge === 2 ? 'Promoção' : null;

  return (
    <Typography
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        fontSize: '0.65rem',
        color: 'white',
        letterSpacing: '0.25px',
        fontWeight: 500,
        paddingX: 1,
        backgroundColor:
          badge === 1 ? '#217d55' : badge === 2 ? 'danger.main' : null,
        textTransform: 'uppercase',
      }}
    >
      {badgeLabel}
    </Typography>
  );
};

export default ProductBadge;
