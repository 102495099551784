import { Typography } from '@mui/material';
import React from 'react';
import { CUSTOM_THEME_COLORS } from '../variables';
import ProductLabel from './ProductLabel';

const ProductStock = ({ stock, hiddenLabel }) => {
  const inStock = false;
  const lastUnits = false;
  const outOfStock = stock <= 0;

  if (outOfStock) {
    return <ProductLabel type='stock' />;
  }

  if (lastUnits) {
    return (
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '0.85rem',
          fontWeight: 500,
          color: CUSTOM_THEME_COLORS.general.yellow,
          '&::before': {
            content: '""',
            width: '8px',
            height: '8px',
            backgroundColor: CUSTOM_THEME_COLORS.general.yellow,
            borderRadius: '100%',
          },
        }}
      >
        {!hiddenLabel && 'Stock Reduzido'}
      </Typography>
    );
  }

  if (inStock) {
    return (
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '0.85rem',
          fontWeight: 500,
          color: CUSTOM_THEME_COLORS.general.green,
          '&::before': {
            content: '""',
            width: '8px',
            height: '8px',
            backgroundColor: CUSTOM_THEME_COLORS.general.green,
            borderRadius: '100%',
          },
        }}
      >
        {!hiddenLabel && 'Disponível'}
      </Typography>
    );
  }
};

export default ProductStock;
