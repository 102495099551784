import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';

const ProductDetailsTable = ({ params }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderTopLeftRadius: 0,
      }}
    >
      <Table>
        <TableBody>
          {params.length !== 0 ? (
            params.map(({ title, value }, index) => (
              <TableRow
                key={`technical-detail-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    width: '50%',
                    paddingX: 4,
                    textAlign: 'left !important',
                  }}
                >
                  {title}
                </TableCell>
                <TableCell sx={{ fontWeight: 700, paddingX: 4, textAlign: 'left !important' }}>
                  {value}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  padding: 4,
                  textAlign: 'left !important',
                }}
              >
                Não existem detalhes técnicos disponíveis.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductDetailsTable;
