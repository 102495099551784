import { Box, Container, Typography } from '@mui/material';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const NewProducts = ({ products }) => {
  return (
    <Container sx={{ paddingY: { xs: 2, md: 4 } }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: '1.5rem',
        }}
      >
        Novidades
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <ProductsCarousel products={products} />
      </Box>
    </Container>
  );
};

export default NewProducts;
