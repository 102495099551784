import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import WhatsAppWidget from './components/WhatsAppWidget';
import { setCollectionTheme } from './state/userSlice';
import { createThemeMethod } from './styles';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { collectionTheme } = user;

  const location = useLocation();
  const { collectionSlug, productSlug } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  //  product collection theme functionality
  useEffect(() => {
    //  IF is 'product detail' page
    if (productSlug) return;

    if (!collectionSlug) {
      if (collectionTheme !== 'general') {
        dispatch(setCollectionTheme('general'));
      }
    } else {
      let collectionName = '';
      switch (collectionSlug) {
        case 'home':
          collectionName = 'home';
          break;
        case 'religiosa':
          collectionName = 'religious';
          break;
        case 'floral':
          collectionName = 'floral';
          break;
        case 'profissional':
          collectionName = 'professional';
          break;
        default:
          break;
      }

      if (collectionName === collectionTheme || collectionName === '') return;
      dispatch(setCollectionTheme(collectionName));
    }
  }, [dispatch, collectionTheme, collectionSlug, productSlug]);

  const newTheme = createThemeMethod(collectionTheme);

  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline>
        <div className='app'>
          <Header />
          <Outlet />
          <Footer />
          <WhatsAppWidget />
        </div>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
