import { Card, CardMedia, Container, Typography } from '@mui/material';
import React, { memo } from 'react';

const ProductsListBanner = memo(({ collectionTheme }) => {
  const bannerData = {
    home: {
      title: 'Linha Home',
      url: '/home',
      image: `${process.env.PUBLIC_URL}/product_list_banner_home.jpg`,
    },
    floral: {
      title: 'Linha Floral',
      url: '/floral',
      image: `${process.env.PUBLIC_URL}/product_list_banner_floral.jpg`,
    },
    religious: {
      title: 'Linha Religiosa',
      url: '/religiosa',
      image: `${process.env.PUBLIC_URL}/product_list_banner_religiosa.jpg`,
    },
    professional: {
      title: 'Linha Profissional',
      url: '/profissional',
      image: `${process.env.PUBLIC_URL}/product_list_banner_profissional.jpg`,
    },
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        marginBottom: { xs: 3, md: 4 },
        paddingX: '0 !important',
      }}
    >
      <Card
        sx={{
          position: 'relative',
          boxShadow: 'none',
          borderRadius: '0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CardMedia
          component='img'
          image={bannerData[collectionTheme].image}
          sx={{
            filter: 'brightness(80%)',
            height: { xs: '150px', sm: '200px', md: '300px' },
          }}
        />

        <Container
          sx={{
            position: 'absolute',
            left: '0',
            right: '0',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.5rem', md: '2rem' },
              lineHeight: '1.15',
              fontWeight: 700,
              color: 'white',
            }}
          >
            {bannerData[collectionTheme].title}
          </Typography>
        </Container>
      </Card>
    </Container>
  );
});

export default ProductsListBanner;
