import { Box, Button, Typography } from '@mui/material';
import SummaryTable from './SummaryTable/SummaryTable';

const CartSummary = ({
  setActiveStep,
  cartItems,
  cartTotal,
  selectedClient,
  role,
}) => {
  return (
    <>
      <Typography
        variant='h2'
        sx={{
          textAlign: 'center',
          marginBottom: { xs: 3, md: 4 },
        }}
      >
        Resumo
        {/* Resumo do Carrinho */}
      </Typography>

      <SummaryTable
        cartItems={cartItems}
        cartTotal={cartTotal}
        selectedClient={selectedClient}
        role={role}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
          marginTop: 2,
        }}
      >
        <Button
          variant='contained'
          color='secondary'
          href='/'
          title='Voltar à Loja'
        >
          Voltar à Loja
        </Button>

        <Button
          variant='contained'
          onClick={() => setActiveStep(1)}
          title='Continuar'
        >
          Continuar
        </Button>
      </Box>
    </>
  );
};

export default CartSummary;
