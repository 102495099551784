import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmOrder } from '../../../api/checkout';
// import { postalCodePatternIsValid } from '../../../helpers';
import { StyledTextButton } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';
import { useSelector } from 'react-redux';

const BudgetRequest = ({ setActiveStep, cart: cartObject }) => {
  const navigate = useNavigate();
  // const { id: cartId, cart: cartItems } = cartObject;
  const { cart: cartItems } = cartObject;

  const sessionId = useSelector((state) => state.user.sessionId);

  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    errorMessage: '',
  });
  const [budgetRequestDetails, setBudgetRequestDetails] = useState({
    session_id: sessionId,
    // cart_id: cartId,
    cart: cartItems,
  });

  const onChangeField = (e) => {
    const value = e.target.value;
    const id = e.target.id;

    setBudgetRequestDetails((values) => ({
      ...values,
      [id]: value,
    }));
  };

  function handleConfirmOrder(e) {
    e.preventDefault();
    setIsLoading(true);

    confirmOrder(budgetRequestDetails)
      .then(() => {
        setIsLoading(false);
        setActiveStep(2);
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setIsLoading(false);
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  }

  return (
    <Grid container>
      <Grid
        xs={12}
        smOffset={2}
        sm={8}
        mdOffset={3}
        md={6}
      >
        <Box
          sx={{
            maxWidth: '80%',
            textAlign: 'center',
            marginBottom: { xs: 2, md: 3 },
            marginX: 'auto',
          }}
        >
          <Typography variant='h2'>Pedido de orçamento</Typography>
          <Typography
            sx={{
              marginTop: { xs: 2, md: 2 },
            }}
          >
            Precisa de um orçamento? Preencha o formulário e responderemos com a
            maior brevidade possível.
          </Typography>
        </Box>

        <Box
          component='form'
          onSubmit={handleConfirmOrder}
        >
          <TextField
            id='name'
            label='Nome'
            required
            fullWidth
            onChange={(e) => onChangeField(e)}
          />
          <TextField
            id='companyName'
            label='Nome da empresa'
            required
            fullWidth
            onChange={(e) => onChangeField(e)}
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='taxNumber'
              label='NIF'
              type='number'
              required
              fullWidth
              onChange={(e) => onChangeField(e)}
            />
            <TextField
              id='email'
              label='E-mail'
              type='email'
              required
              fullWidth
              onChange={(e) => onChangeField(e)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='phoneNumber'
              label='Telefone'
              type='tel'
              fullWidth
              onChange={(e) => onChangeField(e)}
            />
            <TextField
              id='mobileNumber'
              label='Telemóvel'
              type='tel'
              required
              fullWidth
              onChange={(e) => onChangeField(e)}
            />
          </Box>
          <TextField
            id='street'
            label='Morada'
            required
            fullWidth
            onChange={(e) => onChangeField(e)}
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='postalCode'
              label='Código Postal'
              required
              fullWidth
              onChange={(e) => onChangeField(e)}
            />
            <TextField
              id='city'
              label='Localidade'
              required
              fullWidth
              onChange={(e) => onChangeField(e)}
            />
          </Box>
          <TextField
            id='notes'
            label='Observações'
            multiline
            rows={3}
            defaultValue=''
            fullWidth
            onChange={(e) => onChangeField(e)}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginTop: 3,
            }}
          >
            <StyledTextButton
              variant='contained'
              color='secondary'
              onClick={() => setActiveStep(0)}
              title='Voltar atrás'
              sx={{ marginLeft: 'auto' }}
            >
              Voltar atrás
            </StyledTextButton>

            <LoadingButton
              variant='contained'
              type='submit'
              title='Finalizar Pedido'
              loading={isLoading}
            >
              Finalizar Pedido
            </LoadingButton>

            <PopupNotification
              notificationProps={notificationProps}
              setNotificationProps={setNotificationProps}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BudgetRequest;
