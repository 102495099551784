import { Box, Typography } from '@mui/material';
import React from 'react';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const ProductRelated = ({ related }) => {
  return (
    <Box marginTop={{ xs: 4, md: 8 }}>
      <Typography
        variant='h2'
        marginBottom={{ xs: 2, md: 4 }}
      >
        Produtos Relacionados
      </Typography>
      <ProductsCarousel products={related} />
    </Box>
  );
};

export default ProductRelated;
