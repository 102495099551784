import { Box, TextField } from '@mui/material';

const PersonalInfo = ({ handleChange, data }) => {
  const { name, phone, email } = data;

  return (
    <Box>
      <TextField
        fullWidth
        required
        type='text'
        name='name'
        label='Nome'
        value={name}
        onChange={handleChange}
        error={!name}
        helperText={!name && 'Campo obrigatório.'}
      />
      <TextField
        fullWidth
        required
        type='text'
        name='phone'
        label='Telemóvel'
        value={phone}
        onChange={handleChange}
        error={!phone}
        helperText={!phone && 'Campo obrigatório.'}
      />
      <TextField
        fullWidth
        required
        type='email'
        name='email'
        label='Email'
        value={email}
        onChange={handleChange}
        error={!email}
        helperText={!email && 'Campo obrigatório.'}
      />
    </Box>
  );
};

export default PersonalInfo;
